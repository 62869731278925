import LocalData from "../persistencia/LocalData";
import AuthService from "../services/auth.service";


const postCrearUsuario = (json) => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const resp = fetch(`${URL}usuarios`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postValidarOtp = (json) => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const resp = fetch(`${URL}usuarios/validar-otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getReenviarOtp = (valor) => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const resp = fetch(`${URL}usuarios/reenviar-otp/${valor}`, {
    method: "GET",
    
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getRestablecerPassword = (valor) => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const resp = fetch(`${URL}usuarios/solicitud-restablecer-password/${valor}`, {
    method: "GET",
    
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postValidarOtpCambiarPassword = (json) => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const resp = fetch(`${URL}usuarios/validar-otp-restablecer-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRestablecerPassword = (json) => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const resp = fetch(`${URL}usuarios/restablecer-password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getCambiaContrasena = (getParameter, json) => {
  console.log(getParameter);
  const URL = process.env.REACT_APP_URL_API_FORANEOS;

  console.log(URL);

  const resp = fetch(`${URL}usuarios/cambiarpassword/${getParameter}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const UsuarioService = {
  postCrearUsuario,
  postValidarOtp,
  getReenviarOtp,
  getRestablecerPassword,
  postValidarOtpCambiarPassword,
  postRestablecerPassword,
  getCambiaContrasena
};

export default UsuarioService;

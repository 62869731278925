import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ComponentesContext } from '../../contextos/componentesContext';
import {
  Typography,
  Button,
  Card,
  Collapse,
  Modal,
  List,
  Upload,
  Tooltip,
  Avatar,
  Spin,
  Row,
  Col,
  Steps,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  SettingOutlined,
  CloseOutlined,
  UploadOutlined,
  DeleteOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';

import {
  FaFileSignature,
  FaMoneyCheckDollar,
  FaCheck,
  FaCircleCheck,
  FaCircleMinus,
  FaPencil,
  FaRegPenToSquare,
  FaFileArrowUp,
  FaComments,
  FaSquareCheck,
  FaSquarePlus,
  FaSquarePen,
  FaUserDoctor,
  FaHospital,
  FaLocationDot,
  FaClipboardCheck,
  FaClipboard,
  FaFingerprint,
  FaMagnifyingGlass,
  FaFileContract,
} from 'react-icons/fa6';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './SolicitudesActivas.css';
import imagen1 from '../../asset/images/vigenciadocumento.png';
import imagen2 from '../../asset/images/check.png';
import AuthService from '../../services/auth.service';
import FormulariosService from '../../services/formularios.Service';
import EntrevistaPld from '../../componentes/EntrevistaPld';

import returnValidUploadFileClass from '../../clases/returnValidUploadFile.class';

const { Panel } = Collapse;

const Solicitudes = () => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const {
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    UpdateFlagtimeLineForm,
    usuarioSesion,
    ModalMessage,
    CloseSession,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    isModalOpenPLD,
    UpdatesetisModalOpenPLD,
    ShowLoading,
    UpdateFooterText,
    UpdateFlagMenuInicial,
    UpdateFlagtimeLineFormAval,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const location = useLocation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth - 60,
    height: window.innerHeight - 190,
    heightScroll: window.innerHeight - 150,
  });

  const [lblBtnContinuar, setlblBtnContinuar] = useState('Continuar Solicitud');

  const [solicitudesPendientes, setsolicitudesPendientes] = useState([]);

  const [UidDocumentoBorrar, setUidDocumentoBorrar] = useState(0);
  const [isModalOpenDelete, setisModalOpenDelete] = useState(false);

  const [SolicitudId, setSolicitudId] = useState(0);
  const [ModelAttachementType, setModelAttachementType] = useState(1);
  const [canRemove, setcanRemove] = useState(true);
  const [isModalOpenDocs, setisModalOpenDocs] = useState(false);
  const [ValcenterSlidePercentage, setValcenterSlidePercentage] =
    useState(33.8);
  const [ValcenterModeCards, setValcenterModeCards] = useState(true);
  const [FlagClientePF, setFlagClientePF] = useState(true);
  const [FlagAvalPF, setFlagAvalPF] = useState(true);
  const [FormalizacionVisible, setFormalizacionVisible] = useState(false);
  const [FormulariosVisible, setFormulariosVisible] = useState(false);
  const [statusSolicitud, setstatusSolicitud] = useState(0);
  const [mensajeSolicitud, setmensajeSolicitud] = useState(<></>);
  const [btnContinuarVisible, setbtnContinuarVisible] = useState(false);
  const [loadingModal, setloadingModal] = useState(false);

  const [TipoDocumentoIdBorrar, setTipoDocumentoIdBorrar] = useState(0);

  const [currentStep, setcurrentStep] = useState(0);

  const [titleState0, settitleState0] = useState('Configuración');
  const [titleState1, settitleState1] = useState(
    <>
      SATWS <br></br> y Buró
    </>
  );
  const [titleState2, settitleState2] = useState('En Análisis');
  const [titleState3, settitleState3] = useState('Formalización');
  const [titleState4, settitleState4] = useState('Resolución');
  const [titleState5, settitleState5] = useState('Resolución');

  const [lblPendientes, setlblPendientes] = useState('');

  const [arrayDocumentos, setarrayDocumentos] = useState([
    {
      id: 1,
      status: 0,
      fileList: [],
    },
    {
      id: 2,
      status: 0,
      fileList: [],
    },
    {
      id: 3,
      status: 0,
      fileList: [],
    },
    {
      id: 4,
      status: 0,
      fileList: [],
    },
    {
      id: 5,
      status: 0,
      fileList: [],
    },
    {
      id: 6,
      status: 0,
      fileList: [],
    },
    {
      id: 7,
      status: 0,
      fileList: [],
    },
    {
      id: 8,
      status: 0,
      fileList: [],
    },
  ]);

  const [dataSolicitud, setdataSolicitud] = useState([
    {
      solicitudId: 0,
      nombreProducto: 'Solicitud de Crédito Express',
      labelStatus: 'Completado: 1/9',
      montoSolicitado: '$1,000,000',
      formularios: [
        {
          formularioId: 1,
          nombreFormulario: 'Configura tu arrendamiento',
          statusId: 1,
        },
        {
          formularioId: 2,
          nombreFormulario: 'Información del cliente',
          statusId: 0,
        },

        {
          formularioId: 3,
          nombreFormulario: 'Información de la empresa',
          statusId: 0,
        },
        {
          formularioId: 4,
          nombreFormulario: 'Domicilio de localización',
          statusId: 0,
        },
        {
          formularioId: 5,
          nombreFormulario: 'Datos de contacto',
          statusId: 0,
        },
        {
          formularioId: 6,
          nombreFormulario: 'Información del aval',
          statusId: 0,
        },
        {
          formularioId: 7,
          nombreFormulario: 'Entrevista de prevención de lavado de dinero',
          statusId: 0,
        },
        {
          formularioId: 8,
          nombreFormulario: 'Firma "Mi Fiel" del Cliente',
          statusId: 0,
        },
        {
          formularioId: 9,
          nombreFormulario: 'Firma "Mi Fiel" del Aval',
          statusId: 0,
        },
      ],
      formalizacion: [
        {
          formalizacionId: 1,
          nombreFormalizacion: 'Subir Documentación',
          statusId: 0,
        },
      ],
    },
  ]);

  const dataUploadPF = [
    <div
      style={{
        textAlign: 'center',
        width: 380,
        fontSize: 25,
        fontWeight: 'bold',
        color: '#4c4cbc',
      }}
    >
      Cliente
    </div>,
    <UploadDocument
      id={1}
      multiple={false}
      title={<>Identificación oficial del representante legal</>}
      url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
      tooltipmessage={
        'Credencial para votar o pasaporte vigente, en el caso de Persona Moral se debe presentar la del Representante Legal. Solicitar CURP si esta no se encuentra en la identificación proporcionada.'
      }
    />,
    <UploadDocument
      id={2}
      multiple={false}
      title={'Comprobante de domicilio de la empresa'}
      url={
        'Telefonía fija, energía eléctrica o agua potable. Se debe presentar el de la empresa y domicilio particular o del Representante Legal.'
      }
      tooltipmessage={''}
    />,
    // <UploadDocument
    //   id={3}
    //   multiple={false}
    //   title={'Carátula del estado de cuenta para dispersión'}
    //   url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
    //   tooltipmessage={
    //     'En caso de venir más de una cuenta se deberá especificar la cuenta en donde se dispersarán los recursos.'
    //   }
    // />,

    FlagClientePF ? (
      <></>
    ) : (
      <UploadDocument
        id={4}
        multiple={true}
        title={'Información Coorporativa'}
        url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
        tooltipmessage={
          'Acta constitutiva; y en caso de existir cambios en el objeto, participación accionaria, otorgamiento o revocación de poderes deberá presentar las actas de asamblea de los últimos movimientos.'
        }
      />
    ),

    <UploadDocument
      id={8}
      multiple={false}
      title={'Comprobante de ingresos'}
      url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
      tooltipmessage={''}
    />,
  ];

  const dataUploadMoral = [
    <div
      style={{
        textAlign: 'center',
        width: 380,
        fontSize: 25,
        fontWeight: 'bold',
        color: '#4c4cbc',
      }}
    >
      Cliente
    </div>,
    <UploadDocument
      id={1}
      multiple={false}
      title={<>Identificación oficial del representante legal</>}
      url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
      tooltipmessage={
        'Credencial para votar o pasaporte vigente, en el caso de Persona Moral se debe presentar la del Representante Legal. Solicitar CURP si esta no se encuentra en la identificación proporcionada.'
      }
    />,
    <UploadDocument
      id={2}
      multiple={false}
      title={'Comprobante de domicilio de la empresa'}
      url={
        'Telefonía fija, energía eléctrica o agua potable. Se debe presentar el de la empresa y domicilio particular o del Representante Legal.'
      }
      tooltipmessage={''}
    />,
    // <UploadDocument
    //   id={3}
    //   multiple={false}
    //   title={'Carátula del estado de cuenta para dispersión'}
    //   url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
    //   tooltipmessage={
    //     'En caso de venir más de una cuenta se deberá especificar la cuenta en donde se dispersarán los recursos.'
    //   }
    // />,

    FlagClientePF ? (
      <></>
    ) : (
      <UploadDocument
        id={4}
        multiple={true}
        title={'Información Coorporativa'}
        url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
        tooltipmessage={
          'Acta constitutiva; y en caso de existir cambios en el objeto, participación accionaria, otorgamiento o revocación de poderes deberá presentar las actas de asamblea de los últimos movimientos.'
        }
      />
    ),

    <UploadDocument
      id={8}
      multiple={false}
      title={'Comprobante de ingresos'}
      url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
      tooltipmessage={''}
    />,

    <div
      style={{
        textAlign: 'center',
        width: 380,
        fontSize: 25,
        fontWeight: 'bold',
        color: '#4c4cbc',
      }}
    >
      Aval
    </div>,
    <UploadDocument
      id={5}
      multiple={false}
      title={'Identificación oficial del representante legal'}
      url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
      tooltipmessage={
        'Credencial para votar o Pasaporte vigente, en el caso de Persona Moral se debe presentar la del Representante Legal. Solicitar CURP si esta no se encuentra en la identificación proporcionada. '
      }
    />,
    <UploadDocument
      id={6}
      multiple={false}
      title={'Comprobante de domicilio de la empresa'}
      url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
    />,
    FlagAvalPF ? (
      <></>
    ) : (
      <UploadDocument
        id={7}
        multiple={true}
        title={'Información Coorporativa'}
        url={'http://192.168.0.91/pdfs/ff_mutuante.pdf'}
        tooltipmessage={
          'Acta constitutiva; y en caso de existir cambios en el objeto, participación accionaria, otorgamiento o revocación de poderes deberá presentar las actas de asamblea de los últimos movimientos.'
        }
      />
    ),
  ];

  const handleSubirDocumentos = () => {
    setisModalOpenDocs(true);
  };

  // 01   INICIALIZA EL FORMULARIO----------------------------------------------------------------------------------------
  const CargaSolicitudId = async () => {
    ShowLoading(true);

    let respuesta = await FormulariosService.getSolicitudes();
    //console.log('solicitudes', respuesta);
    if (respuesta.result != true) {
      let respuestaSesion = await AuthService.getValidaSesion();
      //console.log('respuesta dash validacion', respuestaSesion);
      if (respuestaSesion.result == false) {
        ShowLoading(false);
        CloseSession();
        return false;
      }

      ModalMessage('Error', 'Se produjo un error al cargar las solicitudes.');

      ShowLoading(false);

      return false;
    }

    var solicitudId = 0;
    if (respuesta.data.length > 0) {
      solicitudId = respuesta.data[0].id;
    }

    //setFlagAvalPF

    const tipoRegimenFiscal = respuesta.data[0].cliente?.tipoRegimenFiscal;

    if (tipoRegimenFiscal !== null && tipoRegimenFiscal !== undefined) {
      if (respuesta.data[0].cliente.tipoRegimenFiscal.id == 2) {
        setFlagClientePF(false);
      } else {
        setFlagClientePF(true);
      }
    } else {
      setFlagClientePF(true);
    }

    setSolicitudId(solicitudId);

    let respuestaSolicitudes = await FormulariosService.getSolicitudesActivas();
    let respuestaAval = await FormulariosService.getDatosAval(solicitudId);

    //console.log(respuestaSolicitudes);

    if (respuestaAval.result != true) {
      let respuestaSesion = await AuthService.getValidaSesion();
      //console.log('respuesta dash validacion', respuestaSesion);
      if (respuestaSesion.result == false) {
        ShowLoading(false);
        CloseSession();
        return false;
      }
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar las solicitudes.');
      return false;
    }

    if (respuestaSolicitudes.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      //console.log(respuestaSesion);
      if (respuestaSesion.result == false) {
        CloseSession();
      }
      //ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    }

    if (respuestaAval.data.avalTipoRegimenFiscalId == 2) {
      setFlagAvalPF(false);
    } else {
      setFlagAvalPF(true);
    }

    //console.log("aval", respuestaAval);

    var idStatusSolicitud = 0;

    if (respuestaSolicitudes.estado == 500) {
      idStatusSolicitud = 10;
    } else {
      idStatusSolicitud = respuestaSolicitudes.data[0].statusId;
    }

    //console.log(respuestaSolicitudes.data[0].formularios);

    const filteredDataSolicitudesPendientes =
      respuestaSolicitudes.data[0].formularios.filter(
        (item) => item.statusId === 0
      );

    setsolicitudesPendientes(filteredDataSolicitudesPendientes);

    //console.log(solicitudesPendientes);

    let respuestaDocumentosSubidos =
      await FormulariosService.getListaDocumentos(solicitudId);

    //console.log('documentos', respuestaDocumentosSubidos);

    const newArrayDocumentos = [...arrayDocumentos];

    //console.log('arreglo documentos antes', newArrayDocumentos);

    const Contadores = [
      { id: 1, contador: 0 },
      { id: 2, contador: 0 },
      { id: 3, contador: 0 },
      { id: 4, contador: 0 },
      { id: 5, contador: 0 },
      { id: 6, contador: 0 },
      { id: 7, contador: 0 },
      { id: 8, contador: 0 },
    ];

    respuestaDocumentosSubidos.data.documentoList.map((array, index) => {
      const existe = newArrayDocumentos.findIndex(
        (documento) =>
          documento.id === (array.tipoDocumento == 15 ? 8 : array.tipoDocumento)
      );

      //console.log("existe", existe);

      const existeContador = Contadores.findIndex(
        (contador) =>
          contador.id === (array.tipoDocumento == 15 ? 8 : array.tipoDocumento)
      );

      if (existeContador !== -1) {
        Contadores[existeContador].contador =
          Contadores[existeContador].contador + 1;
      }

      if (existe !== -1) {
        newArrayDocumentos[existe].status = 1;

        const nuevoDocumento = {
          uid: array.id,
          name: 'documento' + Contadores[existe].contador.toString() + '.pdf',
          status: 'done',
          thumbUrl: imagen2,
        };

        const nuevoArrayDocumentos =
          newArrayDocumentos[existe].fileList.concat(nuevoDocumento);

        newArrayDocumentos[existe].fileList = nuevoArrayDocumentos;

        // newArrayDocumentos[existe].fileList = [
        //   {
        //     uid: array.id,
        //     name: 'documento1.pdf',
        //     status: 'done',
        //     thumbUrl: imagen2,
        //   },
        // ];
      }
    });

    //Carga la lista de DOCUMENTOS
    setarrayDocumentos(newArrayDocumentos);

    //console.log('arreglo actualizado', newArrayDocumentos);
    //console.log('arreglo contadores', Contadores);

    //console.log('solicitudes activas', respuestaSolicitudes.data[0]);
    //console.log(      'solicitudes activas',      respuestaSolicitudes.data[0].formularios.length    );

    var StepVal = 0;

    if (respuestaSolicitudes.data[0].statusId == 0) {
      let strCurrentStep =
        respuestaSolicitudes.data[0].labelFormularios.split('/');

      StepVal = parseInt(strCurrentStep[0]);
    } else if (respuestaSolicitudes.data[0].statusId == 1) {
      StepVal = 10;
    } else if (respuestaSolicitudes.data[0].statusId == 2) {
      StepVal = 10;
    } else if (respuestaSolicitudes.data[0].statusId == 3) {
      StepVal = 11;
    } else {
      StepVal = 12;
    }
    //console.log("label Formulario", respuestaSolicitudes.data[0]);

    //console.log("stepVal", StepVal);

    switch (parseInt(StepVal)) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        //console.log('entro 0');
        setcurrentStep(0);
        break;
      case 6:
      case 7:
      case 8:
      case 9:
        //console.log('entro 1');
        setcurrentStep(1);
        break;
      case 10:
        //console.log('entro 2');
        setcurrentStep(2);
        break;
      case 11:
        //console.log('entro 2');
        setcurrentStep(3);
        break;
      default:
        //console.log('entro 3');
        setcurrentStep(4);
        break;
    }

    var strPendientes = '';
    respuestaSolicitudes.data[0].formularios.map((solicitud, index) => {
      if (solicitud.statusId == 0) {
        strPendientes = strPendientes + solicitud.nombreFormulario + '\n';
      }
    });

    //console.log(strPendientes);

    setlblPendientes(strPendientes);

    var countStatus = 0;

    if (idStatusSolicitud != 10) {
      respuestaSolicitudes.data[0].formularios.map((solicitud, index) => {
        if (solicitud.statusId == 1) {
          countStatus++;
        }
      });
    }

    setstatusSolicitud(idStatusSolicitud);

    if (idStatusSolicitud != 10) {
      respuestaSolicitudes.data[0].formularios.map((solicitud, index) => {
        if (solicitud.statusId == 1) {
          countStatus++;
        }
      });
    }

    //console.log("status solicitud", respuestaSolicitudes.data[0].statusId);

    //switch (respuestaSolicitudes.data[0].statusId) {
    switch (parseInt(respuestaSolicitudes.data[0].statusId)) {
      case 0:
        setmensajeSolicitud(<></>);
        setFormalizacionVisible(false);
        setFormulariosVisible(true);
        setbtnContinuarVisible(true);
        setlblBtnContinuar('Continuar Solicitud');
        break;
      case 1:
        //console.log("entro 1");
        setmensajeSolicitud(
          <>
            <lablel className="lbl-title-message">
              Tu solicitud está en revisión.
            </lablel>
            <p className="p-message">
              Tu solicitud se ha registrado y está siendo revisada por nuestro
              equipo de especialistas, te daremos a conocer el resultado lo más
              pronto posible por este medio y correo electrónico.
            </p>
          </>
        );
        setFormulariosVisible(false);
        setFormalizacionVisible(false);
        setbtnContinuarVisible(false);
        setlblBtnContinuar('Ver Solicitud');
        //setcurrentStep(3);
        break;
      case 3:
        //console.log("entro 3");
        setmensajeSolicitud(
          <>
            <lablel className="lbl-title-message">
              ¡CRÉDITO PREAUTORIZADO!
            </lablel>
            <p className="p-message">
              Nos complace informarte que tu solicitud de Crédito Express ha
              sido preautorizada con éxito. Ahora te invitamos a completar tu
              expediente llenando el segmento de formalización.
            </p>
          </>
        );
        setFormulariosVisible(false);
        setFormalizacionVisible(true);
        setbtnContinuarVisible(false);
        setlblBtnContinuar('Ver Solicitud');

        //setcurrentStep(2);

        break;
      case 4:
        setmensajeSolicitud(
          <>
            <lablel className="lbl-title-message">
              Buscaremos más opciones para ti.
            </lablel>
            <p className="p-message">
              Por el momento no contamos con un producto que se adapte a tus
              necesidades; Seguiremos buscando soluciones adecuadas y nos
              pondremos en contacto contigo.
            </p>
          </>
        );
        setFormalizacionVisible(false);
        setbtnContinuarVisible(false);
        setlblBtnContinuar('Ver Solicitud');
        break;
      default:
        setmensajeSolicitud(<></>);
        setFormalizacionVisible(false);
        setFormulariosVisible(true);
        setbtnContinuarVisible(true);
        break;
    }

    if (idStatusSolicitud != 10) {
      setdataSolicitud(respuestaSolicitudes.data);
    }

    ShowLoading(false);
  };
  //---------------------------------------------------------------------------------------------------------------------------

  const handlePLD = async () => {
    UpdatesetisModalOpenPLD(true);
  };

  const regresaBotonFormalizacion = (id) => {
    if (id == 0) {
      return (
        <Button
          onClick={handleSubirDocumentos}
          type="primary"
          className="btnDocumentos"
        >
          <FaFileArrowUp /> &nbsp;&nbsp;&nbsp;Subir Documentación
        </Button>
      );
    } else {
      return (
        <Button
          onClick={handlePLD}
          type="primary"
          className="btnDocumentos"
          style={{ textAlign: 'left' }}
        >
          <FaComments /> &nbsp;&nbsp;&nbsp;Entrevista de PLD
        </Button>
      );
    }
  };

  /*
  const regresaIconoStatusFormalizacion = (statusId) => {
    if (statusId == 0) {
      return (
        <Button
          onClick={handleSubirDocumentos}
          type="primary"
          className="btnDocumentos"
          style={{ width: 240 }}
        >
          <FaFileArrowUp /> &nbsp;&nbsp;&nbsp;Subir Documentación
        </Button>
      );
    } else {
      return (
        <Button
          onClick={handlePLD}
          type="primary"
          className="btnDocumentos"
          style={{ width: 240, textAlign: 'left' }}
        >
          <FaComments /> &nbsp;&nbsp;&nbsp;Entrevista de PLD
        </Button>
      );
    }
  };
  */

  const datosDocumentos = [
    {
      name: (
        <>
          <Button
            onClick={handleSubirDocumentos}
            type="primary"
            className="btnDocumentos"
            style={{ width: 240 }}
          >
            <FaFileArrowUp /> &nbsp;&nbsp;&nbsp;Subir Documentación
          </Button>{' '}
        </>
      ),
      icon: <FaCircleMinus className="IconPending" />,
    },
    {
      name: (
        <Tooltip
          overlayStyle={{ minWidth: '300px' }}
          placement="top"
          title={'Formulario de Entrevista de Prevención de Labado de Dinero.'}
        >
          <Button
            onClick={handlePLD}
            type="primary"
            className="btnDocumentos"
            style={{ width: 240, textAlign: 'left' }}
          >
            <FaComments /> &nbsp;&nbsp;&nbsp;Entrevista de PLD
          </Button>{' '}
        </Tooltip>
      ),
      icon: <FaCircleMinus className="IconPending" />,
    },
  ];

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      if (window.innerWidth > 800) {
        setValcenterSlidePercentage(33.8);
        setValcenterModeCards(true);

        //console.log('entro normal');
      } else {
        //console.log('entro movil');

        setValcenterSlidePercentage(100);
        setValcenterModeCards(false);
      }
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth - 60,
          height: window.innerHeight - 190,
          heightScroll: window.innerHeight - 150,
        });

        if (window.innerWidth > 800) {
          setValcenterSlidePercentage(33.8);
          setValcenterModeCards(true);

          //console.log('entro normal');
        } else {
          //console.log('entro movil');

          setValcenterSlidePercentage(100);
          setValcenterModeCards(false);
        }
      };

      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdateFlagtimeLineForm(false);
      UpdateFlagtimeLineFormMovil(false);
      UpdateFlagtimeLineFormAval(false);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
      UpdateFlagtimeLineFormProductos(true);
      UpdateFlagMenuInicial(false);
      CargaSolicitudId();

      window.addEventListener('resize', handleResize);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const InicializaFormulario = () => {};

  const [expandIconPosition, setExpandIconPosition] = useState('start');
  const onPositionChange = (newExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key) => {
    //console.log(key);
  };

  const handleEditar = async (idSolicitud) => {
    let respuesta = await FormulariosService.getSolicitudes();

    //console.log('respuesta dash', respuesta);

    //   let respuesta = {
    //     "result": true,
    //     "msg": "OK",
    //     "data": []
    // };

    if (respuesta.result != true) {
      let respuestaSesion = await AuthService.getValidaSesion();
      //console.log('respuesta dash validacion', respuestaSesion);
      if (respuestaSesion.result == false) {
        CloseSession();
        return false;
      }

      ModalMessage('Error', 'Se produjo un error al cargar las solicitudes.');

      return false;
    }
    var solicitudId = idSolicitud;

    // if (respuesta.data.length > 0) {
    //   //console.log('entro dash mayor 0');
    //   solicitudId = respuesta.data[0].id;
    // }
    //console.log('solicitudId', solicitudId);

    navigate('/configurar_credito', {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const handleModalCloseDocs = () => {
    window.location.reload();
    setisModalOpenDocs(false);
  };

  const handleModalCloseDelete = () => {
    setisModalOpenDelete(false);
  };

  const handleModalClosePLD = () => {
    UpdatesetisModalOpenPLD(false);
  };

  /*
  const handleBeforeUpload = async (file) => {
    
    console.log(file);
    const formData = new FormData();
    formData.append('documento', file);

    const URL = process.env.REACT_APP_URL_API_FORANEOS;

    ShowLoading(true);
    
    fetch(`${URL}solicitudes/formularios/documentos?solicitudId=${SolicitudId}&tipoDocumento=${1}`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
      },
      body: formData, 
    })
      .then((response) => response.json())
      .then((data) => {
        // Manejar la respuesta aquí
        console.log('Respuesta:', data);
        ShowLoading(false);

        window.location.reload();
        /*
        navigate("/documentos", {
          state: { record: { solicitudId: intSolicitudId } },
        });
        

      })
      .catch((error) => {
        // Manejar errores
        ShowLoading(false);
        console.error('Error al enviar la solicitud:', error);
      });
    
  };
*/

  /*
  const handleFileChange = (info) => {
    console.log('archivo', info);
    console.log('status', info.file);
    if (info.file.status === 'done') {
      // Aquí se ejecutará el fetch cuando el archivo se ha subido
      const formData = new FormData();
      formData.append('archivo', info.file.originFileObj);


      fetch(
        `http://192.168.0.91:8200/solicitudes/formularios/documentos?solicitudId=${SolicitudId}&tipoDocumento=${1}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${AuthService.getCurrentToken()}`,
          },
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // Manejar la respuesta aquí
          console.log('Respuesta:', data);
          ShowLoading(false);
          window.location.reload();
        })
        .catch((error) => {
          // Manejar errores
          ShowLoading(false);
          console.error('Error al enviar la solicitud:', error);
        });
    }
  };
  */

  const handleUploadClick = () => {};

  //BOTON ADJUNTAR(AGREGAR)
  const uploadButton = (FlagArchivos, tipoDocumento) => (
    <a onClick={handleUploadClick} style={{ color: '#4c4cbc' }}>
      <div className="btnUploadFile">
        {FlagArchivos == 0 ? (
          <FaSquarePlus
            style={{ fontSize: 25, marginTop: -5, verticalAlign: 'middle' }}
          />
        ) : tipoDocumento == 3 || tipoDocumento == 7 ? (
          <FaSquarePlus
            style={{ fontSize: 25, marginTop: -5, verticalAlign: 'middle' }}
          />
        ) : (
          <FaSquarePen
            style={{ fontSize: 25, marginTop: -5, verticalAlign: 'middle' }}
          />
        )}
        &nbsp;{' '}
        <label style={{ fontSize: 11 }}>
          {FlagArchivos == 0
            ? 'AGREGAR DOCUMENTO'
            : tipoDocumento == 4 || tipoDocumento == 7 || tipoDocumento == 8
            ? 'AGREGAR OTRO DOCUMENTO'
            : 'REEMPLAZAR DOCUMENTO'}
        </label>
      </div>
    </a>
  );
  //-----------------------------------------------------------------------------------

  const ActualizaUpload = (id, status, idDocumento) => {
    //console.log(arrayDocumentos);
    const newArrayDocumentos = [...arrayDocumentos];
    const index = newArrayDocumentos.findIndex(
      (documento) => documento.id === id
    );

    if (index !== -1) {
      newArrayDocumentos[index].status = 1;

      // console.log(
      //   'total Documentos',
      //   newArrayDocumentos[index].fileList.length
      // );

      const nuevoDocumento = {
        uid: idDocumento,
        name:
          'documento' +
          (newArrayDocumentos[index].fileList.length + 1).toString() +
          '.pdf',
        status: 'done',
        thumbUrl: imagen2,
      };
      const nuevoArrayDocumentos =
        newArrayDocumentos[index].fileList.concat(nuevoDocumento);

      newArrayDocumentos[index].fileList = nuevoArrayDocumentos;
    }
    setarrayDocumentos(newArrayDocumentos);

    //console.log('documento agregado', newArrayDocumentos);

    //console.log('AGREGO ARREGLO', arrayDocumentos);
  };

  const handleRemove = async () => {
    setloadingModal(true);
    setisModalOpenDelete(false);
    let Uid = UidDocumentoBorrar;

    let respuesta = await FormulariosService.deleteDocumento(Uid);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        setloadingModal(false);
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    const newArrayDocumentos = [...arrayDocumentos];

    newArrayDocumentos[TipoDocumentoIdBorrar].status = 0;

    const updatedArray = newArrayDocumentos.map((item) => {
      const newItem = { ...item };
      if (newItem.fileList && newItem.fileList.length > 0) {
        newItem.fileList = newItem.fileList.filter((file) => file.uid !== Uid);
      }
      return newItem;
    });

    setarrayDocumentos(updatedArray);

    setloadingModal(false);
    ModalMessage('Mensaje', 'El documento se borró correctamente.');

    return true;
  };

  const HandleConfirmDelete = (file, tipoDocumento) => {
    //console.log(file);
    //console.log(tipoDocumento);
    setTipoDocumentoIdBorrar(tipoDocumento);
    setUidDocumentoBorrar(file.uid);
    setisModalOpenDelete(true);
  };

  const handleBeforeUpload = async (file) => {
    let flagArchivoValido =
      await returnValidUploadFileClass.returnPdfValidUploadFile(file.type);

    //console.log(file.type);
    if (flagArchivoValido == false) {
      ModalMessage(
        'Mensaje',
        'El tipo de archivo que se desea adjuntar no es válido, solo se pueden adjuntar archivos PDF.'
      );
      return false;
    }

    const fileSizeLimit = 5 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage('Mensaje', 'El archivo no puede pesar más de 5MB.');
      return false; // Cancela la carga del archivo
    }

    //setloadingModal(true);
    return true;
  };

  //COMPONENTE DE BOTONES PARA SUBIR DOCUMENTOS
  function UploadDocument(props) {
    return (
      <div id={props.id} style={{ marginLeft: 30 }}>
        <Row gutter={16}>
          <Col span={8}>
            <div className="title-tipo-documento">{props.title}</div>
          </Col>
          <Col span={16}>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                borderRadius: 10,
                textAlign: 'center',
                paddingLeft: 7,
              }}
            >
              <Tooltip
                overlayStyle={{ minWidth: '300px' }}
                placement="top"
                title={props.tooltipmessage}
              >
                <Upload
                  name="archivo"
                  customRequest={({ file, onSuccess, onError }) => {
                    setloadingModal(true);
                    // En este punto, puedes personalizar cómo manejar la subida del archivo
                    const formData = new FormData();
                    formData.append('documento', file);
                    // Realiza tu solicitud personalizada
                    fetch(
                      `${URL}solicitudes/formularios/documentos?solicitudId=${SolicitudId}&tipoDocumento=${
                        props.id == 8 ? 15 : props.id
                      }`,
                      {
                        method: 'POST',
                        headers: {
                          Authorization: `Bearer ${AuthService.getCurrentToken()}`,
                        },
                        body: formData,
                      }
                    )
                      .then((response) => response.json())
                      .then((data) => {
                        ActualizaUpload(props.id, true, data.id);
                        setloadingModal(false);
                        ModalMessage(
                          'Mensaje',
                          'El documento se guardó correctamente.'
                        );
                      })
                      .catch((error) => {
                        // Maneja errores
                        setloadingModal(false);
                        ModalMessage(
                          'Error',
                          'Se produjo un error al guardar el documento.'
                        );
                        //console.log('entro mal');
                      });
                  }}
                  listType="picture"
                  fileList={arrayDocumentos[props.id - 1].fileList}
                  showUploadList={{
                    showRemoveIcon: canRemove,
                  }}
                  /*
                  onPreview={handlePreview}
                  
                  onChange={handleChange}
                  
                  onChange={handleFileChange}
                */
                  onRemove={(file) => HandleConfirmDelete(file, props.id - 1)}
                  beforeUpload={handleBeforeUpload}
                >
                  {(props.id == 1 ||
                    props.id == 2 ||
                    props.id == 3 ||
                    props.id == 5 ||
                    props.id == 6) &&
                  arrayDocumentos[props.id - 1].status == 1
                    ? null
                    : uploadButton(
                        arrayDocumentos[props.id - 1].fileList.length,
                        props.id
                      )}
                </Upload>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  //-----------------------------------------------------------------------------------

  const number = 1;

  const handleRegresaColorStep = (step) => {
    if (currentStep < step) {
      return '#d3dfe4';
    } else if (currentStep > step) {
      return '#04bb73';
    } else {
      return '#4c4cbc';
    }
  };

  const handleTamanoStep = (step) => {
    if (currentStep < step) {
      return 30;
    } else if (currentStep > step) {
      return 30;
    } else {
      return 30;
    }
  };

  const listaPendiente = (item) => {
    var divPendiente = null;
    if (item.statusId == 0) {
      divPendiente = <div>* {item.nombreFormulario}</div>;
    }

    return divPendiente;
  };

  //-----------------------------------------------------------------------------------
  //REGRESA LA LISTA DE LAS SOLICITUDES CON SU MENSAJE Y EL ESTATUS DE SUS FORMULARIOS.
  const RegresaPanels = (
    <div
      className="div-img-solicitudes"
      style={{
        borderRadius: 25,
        boxShadow: '0px 0px 22px -7px rgba(0,0,0,0.75)',
        minWidth: 600,
        width: windowSize.width / 2 + 200,
        paddingLeft: 20,
        paddingRight: 40,
        paddingBottom: 20,

        overflow: 'auto',
        maxHeight: windowSize.heightScroll + 50,
        height: windowSize.heightScroll + 50,
      }}
    >
      <div>
        {dataSolicitud.map((item, index) => (
          <div style={{}}>
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                marginTop: 95,
                fontSize: 19,
              }}
            >
              {' '}
              <b>Folio:</b> {item.solicitudId.toString().padStart(8, '0')}
            </div>

            <center>
              <br></br>

              <Row style={{ width: '100%', height: 50 }}>
                <Col className="title-card">
                  Solicitud de Arrendamiento Médico
                </Col>
              </Row>

              <Row style={{ width: '100%' }}>
                <Col>
                  <Row style={{ height: 30 }}>
                    <Col>
                      <b>Monto solicitado:</b> {item.montoSolicitado}
                    </Col>
                  </Row>
                  <Row style={{ height: 30 }}>
                    <Col>
                      <b>Estatus:</b> {item.labelStatus}
                    </Col>
                  </Row>
                  <Row style={{ height: 30 }}>
                    <Col>
                      <b>Formularios:</b> {item.labelFormularios}
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Steps
                    className="steps-pendientes"
                    current={currentStep}
                    responsive={false}
                    labelPlacement="vertical"
                    items={[
                      {
                        title: titleState0,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(0),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(0)}
                            icon={<FaClipboard />}
                          />
                        ),
                      },
                      {
                        title: titleState1,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(1),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(1)}
                            icon={<FaMagnifyingGlass />}
                          />
                        ),
                      },

                      {
                        title: titleState2,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(2),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(2)}
                            icon={<FaClipboardCheck />}
                          />
                        ),
                      },

                      {
                        title: titleState3,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(3),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(3)}
                            icon={<FaFileContract />}
                          />
                        ),
                      },
                      // {
                      //   title: titleState4,
                      //   icon: (
                      //     <Avatar
                      //       style={{
                      //         backgroundColor: handleRegresaColorStep(4),
                      //         marginTop: -10,
                      //       }}
                      //       size={handleTamanoStep(4)}
                      //       icon={<FaCheck />}
                      //     />
                      //   ),
                      // },
                    ]}
                  />
                </Col>
              </Row>

              <Row>
                <Col style={{ textAlign: 'left' }}>
                  <label>
                    <b>Pendientes:</b>
                    <br></br>

                    <div style={{ overflow: 'auto' }}>
                      {item.statusId == 3 ? (
                        <div>* Carga de documentos</div>
                      ) : (
                        solicitudesPendientes.map((item2, index) =>
                          listaPendiente(item2)
                        )
                      )}
                    </div>
                  </label>
                </Col>
              </Row>
            </center>
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                marginRight: 70,
                overflow: 'auto',
              }}
            >
              {item.statusId == 3 ? (
                <Button
                  onClick={() => {
                    setisModalOpenDocs(true);
                  }}
                  type="primary"
                  icon={<UploadOutlined />}
                >
                  {' '}
                  &nbsp; Cargar documentación
                </Button>
              ) : item.statusId <= 3 ? (
                <Button
                  onClick={() => {
                    handleEditar(item.solicitudId);
                  }}
                  type="primary"
                  icon={<FaRegPenToSquare />}
                >
                  {' '}
                  &nbsp; {lblBtnContinuar}{' '}
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ))}
      </div>
      <br></br>
    </div>
  );
  //-----------------------------------------------------------------------------------

  return (
    <div
      style={{
        overflow: 'auto',
        width: windowSize.width,
        maxHeight: windowSize.heightScroll + 50,
        height: windowSize.heightScroll + 50,
        backgroundColor: '#fff',
        marginTop: 5,
        borderRadius: 15,
        paddingLeft: 20,
        paddingRight: 20,

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <center>
        <div className="TitleSolicitudes">Mis Solicitudes</div>
      </center>

      {RegresaPanels}
      <br></br>

      <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: '#081b3e', marginBottom: 10 }}
            >
              <UploadOutlined /> &nbsp; Subir documentos.
            </Typography.Title>
          </div>
        }
        closable={false}
        maskClosable={false}
        style={{ marginTop: 0, maxHeight: 200 }}
        open={isModalOpenDocs}
        onCancel={handleModalCloseDocs}
        footer={[
          <div>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left' }}>
                    <Tooltip
                      overlayStyle={{ minWidth: '500px' }}
                      placement="top"
                      title={<img style={{ width: 480 }} src={imagen1}></img>}
                    >
                      <label style={{ fontSize: 11, width: 200 }}>
                        La{' '}
                        <label style={{ fontWeight: 'bold', fontSize: 11 }}>
                          vigencia
                        </label>{' '}
                        es en relación a la fecha de emisión del documento y la
                        fecha de ingreso a crédito.
                      </label>
                    </Tooltip>
                  </td>
                  <td>
                    <Button
                      type="primary"
                      onClick={handleModalCloseDocs}
                      icon={<CloseOutlined />}
                    >
                      Cerrar
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>,
        ]}
      >
        <Spin spinning={loadingModal} active={true}>
          <div style={{ overflow: 'scroll', height: 600, overflowX: 'hidden' }}>
            <List
              bordered
              style={{ alignContent: 'center' }}
              dataSource={FlagClientePF ? dataUploadPF : dataUploadMoral}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Title level={5} style={{ margin: 0 }}>
                    {item}
                  </Typography.Title>
                </List.Item>
              )}
            />
          </div>
        </Spin>
      </Modal>

      <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: '#081b3e', marginBottom: 10 }}
            >
              <FaComments style={{ color: '#4c4cbc' }} /> &nbsp;{' '}
              <label style={{ color: '#4c4cbc' }}>
                Entrevista de Prevención de Lavado de Dinero.
              </label>
            </Typography.Title>
          </div>
        }
        /*
        closable={false}
        maskClosable={false}
        */
        open={isModalOpenPLD}
        onCancel={handleModalClosePLD}
        footer={[<div></div>]}
        className="modal-pld"
      >
        <EntrevistaPld solicitudId={SolicitudId}></EntrevistaPld>
      </Modal>

      <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: '#081b3e', marginBottom: 10 }}
            >
              <FileExcelOutlined style={{ color: '#4c4cbc' }} /> &nbsp;{' '}
              <label style={{ color: '#4c4cbc' }}>Borrar documento.</label>
            </Typography.Title>
          </div>
        }
        /*
        closable={false}
        maskClosable={false}
        */
        open={isModalOpenDelete}
        onCancel={handleModalCloseDelete}
        footer={[
          <div style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              danger
              onClick={handleRemove}
              icon={<DeleteOutlined />}
            >
              Si
            </Button>
            <Button
              type="primary"
              onClick={handleModalCloseDelete}
              icon={<CloseOutlined />}
            >
              No
            </Button>
          </div>,
        ]}
        className="modal-pld"
      >
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginLeft: -20,
            fontSize: 18,
          }}
        >
          Esta seguro de borrar el documento seleccionado?
        </div>
      </Modal>
    </div>
  );
};

export default Solicitudes;

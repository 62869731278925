import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function AvisoPrivacidad() {
  return (
    <div
      style={{
        backgroundColor: '#fff',
        marginTop: 5,
        borderRadius: 15,
        padding: 20,
        overflow: 'auto',
        maxHeight: window.innerHeight - 300,
      }}
    >
      <div style={{ paddingLeft: 20, paddingRight: 20, overflow: 'auto' }}>
        <center>
          <div
            style={{
              fontSize: 30,
              fontWeight: 900,
              color: '#4c4cbc',
              marginBottom: 20,
            }}
          >
            Aviso de privacidad
          </div>
        </center>

        <div class="col-12 pt-5 mb-5" style={{textAlign:"justify"}}>
          <p>
            <b>
              ADMINISTRADORA DE SOLUCIONES, S.A. DE C.V. SOFOM, E.N.R., y/o
              ARRENDADORA FINAMO, S.A. DE C.V.,
            </b>{' '}
            a quien para efectos del presente aviso se le podrá denominar
            “FINAMO”, con domicilio en Alfonso G. Calderón Velarde 2656 int. 11,
            Col. Desarrollo Urbano Tres Ríos C.P. 80020 Culiacán de Rosales,
            Culiacán, Sinaloa, es responsable del uso y protección de sus datos
            personales, y en cumplimiento a lo establecido en la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares (en
            adelante “la Ley”) y su Reglamento atendiendo los principios de: i)
            licitud, ii) consentimiento, iii) información, iv) calidad, v)
            finalidad, vi) lealtad, vii) proporcionalidad, y viii)
            responsabilidad, establece el presente aviso de privacidad.
          </p>
        </div>

        <div class="col-12 mb-5 " style={{textAlign:"justify"}}>
          <h4>¿Qué datos personales utilizaremos?</h4>
          <p>
            Se hace de su conocimiento que FINAMO podrá recabar de manera
            personal y directa, a través de medios electrónicos y por los medios
            permitidos por la Ley, los datos personales que resulten necesarios
            para los procesos de verificación, así como autorización de
            clientes, obligados solidarios e identificación de propietarios
            reales de clientes de FINAMO, dichos datos son los siguientes:
          </p>
          <ul>
            <li>Datos de identificación.</li>
            <li>Datos de contacto.</li>
            <li>Datos laborales.</li>
            <li>Datos Patrimoniales.</li>
            <li>Datos financieros.</li>
            <li>Datos comerciales.</li>
            <li>Datos de terceros.</li>
          </ul>
          <p>
            FINAMO se compromete a contar con la infraestructura tecnológica,
            material y humana que permita asegurar que sus datos personales sean
            tratados en forma responsable, lícita, rápida, precisa, completa y
            segura. Asimismo, custodiará su información durante el tiempo que
            sea apropiado para los fines mencionados en este aviso o según sea
            requerido por la legislación aplicable.
          </p>
        </div>

        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>¿Para qué fines utilizaremos sus datos personales?</h4>
          <p>
            Los datos personales que recabamos de usted, los utilizaremos para
            las siguientes finalidades que son necesarias para el servicio que
            solicita:
          </p>
          <ul>
            <li>
              La identificación y conocimiento de Usted según sea el caso;
            </li>
            <li>
              La o las consultas en las Sociedades de Información Crediticia de
              la información e historial crediticio.
            </li>
            <li>
              Verificar y autorizar el proceso de prospección comercial,
              incluyendo la revisión y aprobación del área correspondiente
              implementando políticas internas.
            </li>
            <li>
              Prevenir y/o reportar la comisión de delitos, ilícitos o
              irregularidades relacionadas.
            </li>
            <li>
              Utilizarlos en cualquier tipo de acto o diligencia de cobranza
              judicial y/o extrajudicial.
            </li>
            <li>
              Todas aquellas que permitan dar cumplimiento a las obligaciones
              contractuales que sean adquiridas.
            </li>
            <li>Atender requerimientos legales de autoridades competentes.</li>
          </ul>

          <p>
            De manera adicional, utilizaremos su información personal para las
            siguientes finalidades que no son necesarias para el servicio
            solicitado, pero que nos permiten y facilitan brindarle una mejor
            atención:
          </p>
          <ul>
            <li>
              Darle a conocer nuevos productos y/o servicios, así como
              promociones, y en general toda la publicidad derivada de los
              servicios que ofrece FINAMO.
            </li>
            <li>Encuestas de servicio y calidad.</li>
          </ul>

          <p>
            En caso de que no desee que sus datos personales sean tratados para
            estos fines adicionales, desde este momento usted nos puede
            comunicar lo anterior, por medio de correo electrónico que es
            unidadespecializada@finamo.mx, solicitando la negativa para el uso o
            tratamiento de sus datos personales.
          </p>
          <p>
            La negativa para el uso de sus datos personales para estas
            finalidades no podrá ser motivo para que le neguemos los servicios y
            productos que solicita o contrata con nosotros.
          </p>
          <p>
            <b>
              ¿Con quién compartimos su información personal y para que fines?
            </b>
          </p>

          <p>
            Le informamos que sus datos personales pueden ser compartidos dentro
            del país con las siguientes personas, empresas, organizaciones y
            autoridades distintas a nosotros para los siguientes fines:
          </p>

          <ul>
            <li>
              A los socios comerciales con los que FINAMO lleve a cabo convenios
              o contratos orientados al desarrollo de nuevos productos y/o
              servicios enfocados al mejoramiento y de acuerdo a sus
              necesidades.
            </li>
            <li>
              A terceros proveedores de servicios para el cumplimiento de las
              obligaciones legales adquiridas por FINAMO.
            </li>

            <li>
              A terceros proveedores de servicios de investigación, de análisis
              de datos, envío de información enfocada a las necesidades del
              titular de los datos personales, así como en la realización de
              otros servicios necesarios o requeridos por el titular.
            </li>
          </ul>

          <p>
            Todas las finalidades están en los supuestos del artículo 37 de la
            Ley, por lo que no es necesario obtener consentimiento para su
            transferencia.
          </p>
        </div>

        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>
            ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
            oponerse a su uso?
          </h4>
          <p>
            Usted tiene derecho a conocer que datos personales tenemos de usted,
            para que los utilizamos y las condiciones del uso que les damos
            (Acceso). Asimismo, es su derecho solicitar la corrección de su
            información personal en caso de que este desactualizada, sea
            inexacta o incompleta (Rectificación); que la eliminemos de nuestros
            registros o bases de datos cuando considere que la misma no está
            siendo utilizada conforme a los principios, deberes y obligaciones
            previstas en la normativa (Cancelación); así como oponerse al uso de
            sus datos personales para fines específicos (Oposición). Estos
            derechos se conocen como derechos ARCO.
          </p>
          <p>
            Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
            presentar la solicitud respectiva enviando un correo a
            unidadespecializada@finamo.mx.
          </p>
          <p>
            Para conocer el procedimiento y requisitos para el ejercicio de los
            derechos ARCO, usted podrá llamar al siguiente número telefónico
            (667) 758 55 88; ingresar a nuestro sitio de internet
            https://www.finamo.mx/aviso_de_privacidad a la sección de solicitud
            de derechos ARCO, o bien ponerse en contacto con nuestro
            Departamento de Privacidad, que dará tramite a las solicitudes para
            el ejercicio de estos derechos, y atenderá cualquier duda que
            pudiera tener respecto al tratamiento de sus información.
          </p>
          <p>
            Los datos de contacto del Departamento de Privacidad son los
            siguientes:
          </p>
          <p>Nombre: Lic. Martha Carolina Magallanes López.</p>
          <p>
            Domicilio: Alfonso G. Calderón Velarde 2656 int. 11, Col. Desarrollo
            Urbano Tres Ríos C.P. 80020 Culiacán de Rosales, Culiacán, Sinaloa.
          </p>
          <p>Correo electrónico: unidadespecializada@finamo.mx</p>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>
            ¿Cómo puede revocar su consentimiento para el uso de sus datos
            personales?
          </h4>
          <p>
            Usted puede revocar el consentimiento que, en su caso, nos haya
            otorgado para el tratamiento de sus datos personales. Sin embargo,
            es importante que tenga en cuenta que no en todos los casos podremos
            atender su solicitud o concluir el uso de forma inmediata, ya que es
            posible que por alguna obligación legal requiramos seguir tratando
            sus datos personales. Asimismo, usted deberá considerar que, para
            ciertos fines, la revocación de su consentimiento implicara que no
            le podamos seguir prestando el servicio que nos solicitó, o la
            conclusión de su relación con nosotros.
          </p>

          <p>
            Para revocar su consentimiento deberá presentar su solicitud
            enviando un correo electrónico a unidadespecializada@finamo.mx
          </p>

          <p>
            Para conocer el procedimiento y requisitos para la revocación del
            consentimiento, usted podrá llamar al siguiente número telefónico
            (667) 758 55 88; ingresar a nuestro sitio de internet
            https://www.finamo.mx/aviso_de_privacidad a la sección de solicitud
            de derechos ARCO, o bien ponerse en contacto con nuestro
            Departamento de Privacidad.
          </p>

          <h4>
            ¿Cómo puedo limitar el uso o divulgación de su información personal?
          </h4>

          <p>
            Con objeto de que usted pueda limitar el uso y divulgación de su
            información personal, le ofrecemos los siguientes medios:
          </p>

          <ul>
            <li>
              Su inscripción en el Registro Público para Evitar Publicidad, que
              está a cargo de la Procuraduría Federal del Consumidor, con la
              finalidad de que sus datos personales no sean utilizados para
              recibir publicidad o promociones de empresas de bienes o
              servicios. Para mayor información sobre este registro, usted puede
              consultar el portal de Internet de PROFECO, o bien ponerse en
              contacto directo con esta.
            </li>
            <li>
              Su registro en el listado de exclusión, a fin de que sus datos
              personales no sean tratados para fines mercadotécnicos,
              publicitarios o de prospección comercial por nuestra parte.
            </li>
          </ul>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <p>
            Para mayor información llamar al número telefónico (667) 758 55 88,
            enviar correo electrónico a las siguiente dirección
            unidadespecializada@finamo.mx, o bien consultar nuestra página de
            internet https://www.finamo.mx/aviso_de_privacidad.
          </p>

          <h5>
            El uso de tecnologías de rastreo en nuestro portal de Internet
          </h5>
          <p>
            Fínamo recaba y almacena información a través de ingreso a su sitio
            Web, información sobre la dirección IP/nombre de dominio del
            visitante, sitios Web que hagan referencia a su sitio,
            comportamiento y el tiempo de estadía en el sitio Web, las páginas
            navegadas, herramientas utilizadas, código postal, tipo de navegador
            y sistema operativo entre otros.
          </p>
          <p>
            Dicha información se obtiene y almacena con el fin de medir la
            actividad del sitio e identificar tendencias sobre navegación que no
            son atribuibles a un individuo en específico.
          </p>

          <p>
            La información anteriormente señalada se recopila a través de las
            “cookies”, así como otros medios y mecanismos tecnológicos, tales
            como las etiquetas de pixel, los web bugs, enlaces en correos
            electrónicos, web beacons (etiquetas de Internet, etiquetas de pixel
            y clear GIFs) entre otros.
          </p>

          <p>
            La mayoría de los exploradores le permitirán eliminar el uso de
            cookies, bloquear la aceptación de las cookies o recibir una
            advertencia antes de almacenar cookies. Le sugerimos consultar las
            instrucciones de su explorador para utilizar las “cookies”.
          </p>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>USO DE COOKIES</h4>
          <p>
            Su navegador aceptará las cookies y permitirá la recolección
            automática de información a menos que usted cambie la configuración
            predeterminada del navegador. La mayoría de los navegadores web
            permiten que usted pueda gestionar sus preferencias de cookies.
            Puede ajustar su navegador para que rechace o elimine cookies. Los
            siguientes links muestran como ajustar la configuración del
            navegador de los navegadores que son utilizados con más frecuencia:
          </p>

          <ul>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=es"
                target="_blank"
              >
                Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es"
                target="_blank"
              >
                Firefox
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                target="_blank"
              >
                Internet Explorer
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/es-es/HT201265#:~:text=1%20Para%20borrar%20el%20historial%20y%20las%20cookies%2C,en%20el%20historial%2C%20activa%20la%20funci%C3%B3n%20Navegaci%C3%B3n%20privada."
                target="_blank"
              >
                Safari
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>¿Cómo puede conocer los cambios a este aviso de privacidad?</h4>
          <p>
            El presente aviso de privacidad puede sufrir modificaciones, cambios
            o actualizaciones derivadas de nuevos requerimientos legales; de
            nuestras propias necesidades por los productos o servicios que
            ofrecemos; de nuestras prácticas de privacidad; de cambios en
            nuestro modelo de negocio, o por otras causas. Nos comprometemos a
            mantenerlo informado sobre los cambios que pueda sufrir el presente
            aviso de privacidad, a través de nuestra página de internet
            https://www.finamo.mx/aviso_de_privacidad.
          </p>

          <h4>Información sobre el IFAI-INAI</h4>

          <p>
            Si usted considera que su derecho a la protección de sus datos
            personales ha sido lesionado por alguna conducta u omisión de
            nuestra parte, o presume alguna violación a las disposiciones
            previstas en la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares, su reglamento y demás ordenamientos
            aplicables, podrá interponer su inconformidad o denuncia ante el
            Instituto Nacional de Transparencia de Acceso a la Información y
            Protección de Datos (INAI). Para mayor información, le sugerimos
            visitar su página oficial de Internet www.inai.org.mx.
          </p>
          <p>
            Otorgo mi consentimiento para que mis datos personales sean tratados
            conforme a lo previsto en el presente aviso de privacidad.
          </p>

          <p style={{textAlign:"right"}}>
            Última actualización 01 de abril de 2024.
          </p>
        </div>
        
        
        
        
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import './Welcome.css';
import { Carousel, Typography, Button, Collapse } from 'antd';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import imagen1 from '../asset/images/welcomeDoctor.jpg';

import imagen2 from '../asset/images/imagen1.png';

import AuthService from '../services/auth.service';
import FormulariosService from '../services/formularios.Service';

import { ComponentesContext } from '../contextos/componentesContext';

const Welcome = () => {
  const {
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    UpdateFlagtimeLineForm,
    UpdateFooterText,
    ShowLoading,
    CloseSession,
    UpdateFlagMenuInicial,
    UpdateFlagtimeLineFormAval,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;

  const [formVisible, setformVisible] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 100,
    heightImage: window.innerHeight - 185,
  });

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  useEffect(() => {
    ShowLoading(true);
    let usr = AuthService.getCurrentUser();
    if (usr === null || usr === undefined) {
      setformVisible(true);
      UpdatebuttonIngresar('');
      UpdateuserAvatar('');
      UpdateFlagtimeLineForm(false);
      UpdateFlagtimeLineFormAval(false);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
      UpdateFlagMenuInicial(false);

      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 100,
          heightImage: window.innerHeight - 185,
        });
      };
      ShowLoading(false);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    } else {
      handleRedirecciona();
    }
  }, []);

  const handleRedirecciona = async () => {
    //console.log("entro redirecciona");

    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      ShowLoading(false);
      navigate('/dashboard');
      return true;
    }

    let respuestaSesion = await AuthService.getValidaSesion();
    if (respuestaSesion.result == false) {
      CloseSession();
      ShowLoading(false);
      return true;
    }

    let respuesta = await FormulariosService.getSolicitudesActivas();
    //console.log("respuesta redireccion", respuesta.data.length );
    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }
    ShowLoading(false);
    if (respuesta.data.length == 0) {
      navigate('/dashboard');
    } else {
      navigate('/solicitudes_activas');
    }
  };

  const handleNext = (option) => {
    navigate(option);
    //window.location.reload();
  };

  return formVisible ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',

        height: windowSize.height,
      }}
    >
      <table
        width={'100%'}
        style={{
          borderRadius: 15,
          backgroundColor: '#ffffff',
          borderWidth: 0,
          borderStyle: 'none',
          marginTop: 10,
        }}
      >
        <tbody>
          <tr>
            <td className="tab-panel-picture" style={{ width: ' 50%' }}>
              <div style={{ width: '100%' }}>
                <img
                  style={{ width: '100%', borderRadius: 10 }}
                  src={imagen1}
                ></img>
              </div>
            </td>

            <td>
              <table
                cellPadding={0}
                cellSpacing={0}
                style={{
                  width: '100%',
                  borderRadius: 15,
                  marginLeft: 0,
                }}
                border={0}
              >
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ textAlign: 'center' }}>
                      <p>
                        <Title level={2} style={{ marginTop: -5 }}>
                          Bienvenido a nuestro portal digital
                        </Title>
                      </p>
                      <p>
                        Inicia tu precalificación para obtener una línea de
                        Arrendamiento.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right' }}>
                      <Button
                        type="primary"
                        size={'default'}
                        style={{
                          marginTop: 20,
                          color: '#4c4cbc',
                          backgroundColor: '#ffffff',
                          height: 140,
                          marginRight: 20,
                          fontWeight: 'bold',
                          boxShadow: '0px -1px 10px 0px rgba(0,0,0,0.20)',
                          marginBottom: 20,
                        }}
                        onClick={() =>
                          navigate('/login', {
                            state: { record: { correo: '' } },
                          })
                        }
                        className="account-welcome-btn-login"
                      >
                        <UserOutlined style={{ fontSize: '30px' }} />{' '}
                        <p>Ya tengo una cuenta</p>
                      </Button>
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <Button
                        type="primary"
                        size={'default'}
                        style={{
                          marginTop: 20,
                          backgroundColor: '#4c4cbc',
                          height: 140,
                          marginLeft: 20,
                          fontWeight: 'bold',
                          boxShadow: '0px -1px 10px 0px rgba(0,0,0,0.20)',
                          marginBottom: 20,
                        }}
                        onClick={() => handleNext('/create')}
                        className="account-welcome-btn-create"
                      >
                        <PlusOutlined style={{ fontSize: '30px' }} />{' '}
                        <p>Crear una cuenta</p>
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Welcome;

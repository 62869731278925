import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, Outlet, useLocation, Link } from 'react-router-dom';
import Logo from '../componentes/Logo';
import LogoTiny from '../componentes/Logo_tiny';
//import LogoBlanco from "../componentes/Logo_tiny_white";
import imagen1 from '../asset/images/doc.png';
import AuthService from '../services/auth.service';

import FormulariosService from '../services/formularios.Service';

import {
  FaBuilding,
  FaCity,
  FaHotel,
  FaMoneyCheckDollar,
  FaMoneyBill1,
  FaMoneyBillWave,
  FaSackDollar,
  FaDollarSign,
  FaUserPlus,
  FaCloud,
  FaComments,
  FaFileArrowUp,
  FaKey,
  FaFileContract,
  FaFlagCheckered,
  FaLocationDot,
  FaClipboardUser,
  FaIdCard,
  FaCircle,
  FaUserDoctor,
  FaHospital,
  FaFileSignature,
} from 'react-icons/fa6';

import { FaRegHospital } from 'react-icons/fa';

import './Main.css';

import CryptoJS from 'crypto-js';

import {
  Card,
  Typography,
  ConfigProvider,
  Collapse,
  Steps,
  Avatar,
  Button,
  Row,
  Col,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  SearchOutlined,
  IdcardOutlined,
  FundProjectionScreenOutlined,
  BankOutlined,
  AuditOutlined,
  TeamOutlined,
} from '@ant-design/icons';

import { ComponentesContext } from '../contextos/componentesContext';

import RefreshAvalTypeClass from '../clases/refreshAvalType.class';

const { Meta } = Card;
const { Panel } = Collapse;

const Main = (children) => {
  const {
    ModalMessage,
    buttonIngresar,
    UserAvatar,
    UpdateuserAvatar,
    UpdatebuttonIngresar,
    currentStep,
    FlagtimeLineForm,
    movilSteps,
    FlagtimeLineFormMovil,
    FlagtimeLineFormProductos,
    CloseSession,
    FooterText,
    UpdateFooterText,
    FlagMenuInicial,
    UpdateFlagMenuInicial,
    FlagtimeLineFormAval,
    UpdatePendientesAval,
  } = useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [notificationsCounter, setnotificationsCounter] = useState(0);
  const { Title } = Typography;
  const [currentUrl, setCurrentUrl] = useState('');

  const location = useLocation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 140,
    heightImage: window.innerHeight - 200,
  });

  const [titleState0, settitleState0] = useState('');
  const [titleState1, settitleState1] = useState('');
  const [titleState2, settitleState2] = useState('');
  const [titleState3, settitleState3] = useState('');
  const [titleState4, settitleState4] = useState('');

  const handleWindowResize = () => {
    if (window.innerWidth > 1000) {
      settitleState0('');
      settitleState1('');
      settitleState2('');
      settitleState3('');
      settitleState4('');
    } else {
      settitleState0('');
      settitleState1('');
      settitleState2('');
      settitleState3('');
      settitleState4('');
    }
  };

  window.addEventListener('resize', handleWindowResize);

  useEffect(() => {
    const handleScroll = () => {
      //const currentScrollPos = window.pageYOffset;
      //setShowMenu(prevScrollPos > currentScrollPos || currentScrollPos === 0);
      //setPrevScrollPos(currentScrollPos);
    };

    const url = window.location.href;
    const parts = url.split('/');
    const lastPart = parts[parts.length - 1];
    setCurrentUrl(lastPart);

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - 140,
        heightImage: window.innerHeight - 200,
      });

      //console.log(windowSize.height);
    };

    //window.addEventListener("scroll", handleScroll);
    window.addEventListener('resize', handleResize);

    let arrpath = location.pathname.split('/');

    switch (arrpath[1]) {
      case 'solicitudes_activas':
        UpdateFooterText('');
        break;
      case 'create':
      case 'login':
      case 'dashboard':
      case 'configurar_credito':
      case 'datos_cliente':
      case 'datos_empresa':
      case 'domicilio_localizacion':
      case 'datos_contacto':
      case 'datos_aval':
      case 'solicitudes_activas':
      case 'autorizacion_sat':
      case 'firma_buro':
      case 'revision':
      case 'pendientes_firma':
      case 'validar-aval':
      case 'autorizacion_sat_aval':
      case 'firma_buro_aval':
      case 'revision_aval':
        //console.log('entro');
        UpdateFlagMenuInicial(false);
        break;
      default:
        //console.log('entro default');
        UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
        UpdateFlagMenuInicial(true);
        break;
    }

    // if (location.pathname == '/solicitudes_activas') {
    //   UpdateFooterText('');
    // }
    // else if (location.pathname == '/create') {
    //   console.log("main entro");
    //   UpdateFlagMenuInicial(false);
    // }
    // else if (location.pathname == '/login') {
    //   console.log("main entro");
    //   UpdateFlagMenuInicial(false);
    // }
    // else if (location.pathname == '/dashboard') {
    //   console.log("main entro");
    //   UpdateFlagMenuInicial(false);
    // }
    // else {
    //   UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
    //   UpdateFlagMenuInicial(true);
    // }

    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const items = [
    {
      key: '1',
      label: 'This is panel header 1',
      children: 'prueba',
    },
    {
      key: '2',
      label: 'This is panel header 2',
      children: 'prueba',
    },
  ];

  const handleNext = (option) => {
    navigate(option);
  };

  const handleSendHome = async () => {
    let usr = AuthService.getCurrentUser();

    //console.log('Usuario:', usr);

    if (location.pathname == '/dashboard') {
      //window.location.href = 'https://www.finamo.mx/';
      handleNext('/');
      return false;
    }

    if (usr === null || usr === undefined) {
      UpdateuserAvatar('');
      UpdatebuttonIngresar('');
      //window.location.href = 'https://www.finamo.mx/';
      handleNext('/');
    } else {
      UpdatePendientesAval(await RefreshAvalTypeClass.RefreshAvalType());

      let respuesta = await FormulariosService.getSolicitudes();

      //console.log('solicitudes', respuesta);
      if (respuesta.result != true) {
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
        }
        return false;
      }

      let isAval = await RefreshAvalTypeClass.RefreshAvalType();

      if (isAval) {
        navigate('/pendientes_firma');
      } else {
        if (respuesta.data.length > 0) {
          handleNext('/solicitudes_activas');
        } else {
          //console.log('entro a dash');
          handleNext('/dashboard');
        }
      }
    }
  };

  const handleRegresaColorStep = (step) => {
    if (currentStep < step) {
      return '#d3dfe4';
    } else if (currentStep > step) {
      return '#04bb73';
    } else {
      return '#4c4cbc';
    }
  };

  const handleTamanoStep = (step) => {
    if (currentStep < step) {
      return 30;
    } else if (currentStep > step) {
      return 30;
    } else {
      return 30;
    }
  };

  const handleProductos = () => {
    handleNext('/dashboard');
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4c4cbc',
        },
      }}
    >
      <div>
        {showMenu && (
          <div className="div-menu-header">
            <nav
              className="menu"
              style={{ backgroundColor: 'white', display: 'flex' }}
            >
              <a onClick={() => handleSendHome()} style={{ height: 60 }}>
                <Logo />
              </a>
              <div
                style={{ display: FlagtimeLineFormMovil ? 'block' : 'none' }}
              >
                <div
                  className="div-steps-movil"
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  {movilSteps}
                </div>
              </div>

              <div
                className="div-steps"
                style={{ width: FlagtimeLineForm ? '100%' : 0 }}
              >
                <div
                  style={{
                    marginLeft: 30,
                    marginRight: 30,
                    display: FlagtimeLineForm ? 'block' : 'none',
                  }}
                >
                  <ul
                    className="menu-list"
                    style={{
                      height: 37,
                      marginTop: 5,
                    }}
                  >
                    <Steps
                      current={currentStep}
                      responsive={false}
                      items={[
                        {
                          title: titleState0,
                          icon: (
                            <Tooltip title="Configura tu arrendamiento">
                              <Avatar
                                style={{
                                  backgroundColor: handleRegresaColorStep(0),
                                  marginTop: -5,
                                }}
                                size={handleTamanoStep(0)}
                                icon={<FaMoneyCheckDollar />}
                              />
                            </Tooltip>
                          ),
                        },
                        {
                          title: titleState1,
                          icon: (
                            <Tooltip title="Información del cliente">
                              <Avatar
                                style={{
                                  backgroundColor: handleRegresaColorStep(1),
                                  marginTop: -5,
                                }}
                                size={handleTamanoStep(1)}
                                icon={<FaUserDoctor />}
                              />
                            </Tooltip>
                          ),
                        },

                        {
                          title: titleState2,
                          icon: (
                            <Tooltip title="Información de empresa">
                              <Avatar
                                style={{
                                  backgroundColor: handleRegresaColorStep(2),
                                  marginTop: -5,
                                }}
                                size={handleTamanoStep(2)}
                                icon={<FaHospital />}
                              />
                            </Tooltip>
                          ),
                        },
                        {
                          title: titleState3,
                          icon: (
                            <Tooltip title="Domicilio de localización">
                              <Avatar
                                style={{
                                  backgroundColor: handleRegresaColorStep(3),
                                  marginTop: -10,
                                }}
                                size={handleTamanoStep(3)}
                                icon={<FaLocationDot />}
                              />
                            </Tooltip>
                          ),
                        },

                        {
                          title: titleState3,
                          icon: (
                            <Tooltip title="Datos de contacto">
                              <Avatar
                                style={{
                                  backgroundColor: handleRegresaColorStep(4),
                                  marginTop: -10,
                                }}
                                size={handleTamanoStep(4)}
                                icon={<FaClipboardUser />}
                              />
                            </Tooltip>
                          ),
                        },

                        {
                          title: titleState3,
                          icon: (
                            <Tooltip title="Información del Aval">
                              <Avatar
                                style={{
                                  backgroundColor: handleRegresaColorStep(5),
                                  marginTop: -5,
                                }}
                                size={handleTamanoStep(5)}
                                icon={<FaIdCard />}
                              />
                            </Tooltip>
                          ),
                        },

                        /*
                      {
                        title: titleState4, icon: <Avatar
                          style={{ backgroundColor: handleRegresaColorStep(6), marginTop: -10 }}
                          size={handleTamanoStep(6)}
                          icon={<FaFileArrowUp />}
                        />
                      },*/

                        /*
                      {
                        title: titleState4, icon: <Avatar
                          style={{ backgroundColor: handleRegresaColorStep(7), marginTop: -5 }}
                          size={handleTamanoStep(7)}
                          icon={<FaComments />}
                        />
                      },
                      */
                        {
                          title: titleState4,
                          icon: (
                            <Tooltip title="Autorización de Consulta de SAT">
                              <Avatar
                                style={{
                                  backgroundColor: handleRegresaColorStep(8),
                                  marginTop: -5,
                                }}
                                size={handleTamanoStep(8)}
                                icon={<FaCloud />}
                              />
                            </Tooltip>
                          ),
                        },

                        {
                          title: titleState4,
                          icon: (
                            <Tooltip title="Autorización de Consulta de Buró">
                              <Avatar
                                style={{
                                  backgroundColor: handleRegresaColorStep(9),
                                  marginTop: -5,
                                }}
                                size={handleTamanoStep(9)}
                                icon={<FaFileSignature />}
                              />
                            </Tooltip>
                          ),
                        },

                        {
                          title: titleState4,
                          icon: (
                            <Tooltip title="Solicitud en revisión">
                              <Avatar
                                style={{
                                  backgroundColor: handleRegresaColorStep(10),
                                  marginTop: -5,
                                }}
                                size={handleTamanoStep(10)}
                                icon={<FaFlagCheckered />}
                              />
                            </Tooltip>
                          ),
                        },
                      ]}
                    />
                  </ul>
                </div>
              </div>

              <div
                className="div-steps"
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  display: FlagtimeLineFormAval ? 'block' : 'none',
                }}
              >
                <ul
                  className="menu-list"
                  style={{
                    height: 37,
                    marginTop: 5,
                  }}
                >
                  <Steps
                    current={currentStep}
                    responsive={false}
                    items={[
                      {
                        title: titleState4,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(8),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(8)}
                            icon={<FaCloud />}
                          />
                        ),
                      },

                      {
                        title: titleState4,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(9),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(9)}
                            icon={<FaFileSignature />}
                          />
                        ),
                      },

                      {
                        title: titleState4,
                        icon: (
                          <Avatar
                            style={{
                              backgroundColor: handleRegresaColorStep(10),
                              marginTop: -5,
                            }}
                            size={handleTamanoStep(10)}
                            icon={<FaFlagCheckered />}
                          />
                        ),
                      },
                    ]}
                  />
                </ul>
              </div>

              <div
                className="div-btn-producto"
                style={{
                  width:
                    FlagtimeLineFormAval ||
                    FlagtimeLineForm ||
                    FlagtimeLineFormMovil
                      ? ''
                      : '100%',
                }}
              >
                <div
                  className="div-productos"
                  style={{
                    textAlign: 'right',
                    display: FlagtimeLineFormProductos ? 'block' : 'none',
                  }}
                >
                  <Button type="primary" onClick={handleProductos}>
                    Más productos
                  </Button>
                </div>
              </div>

              <div
                className="div-menu-inicial"
                style={{
                  display: FlagMenuInicial ? 'block' : 'none',
                }}
              >
                <Row gutter={16} justify="end">
                  <Col span={8}>
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                        marginLeft: 15,
                      }}
                    >
                      <Button
                        onClick={() => handleNext('/Nosotros')}
                        style={{ height: 40 }}
                      >
                        Nosotros
                      </Button>
                    </div>
                  </Col>

                  <Col span={8}>
                    <Button
                      type="primary"
                      onClick={() => handleNext('/welcome')}
                      style={{ height: 40 }}
                    >
                      Precalifícate
                    </Button>
                  </Col>
                </Row>
              </div>

              <ul className="menu-list" style={{ height: 37 }}>
                <Title
                  level={5}
                  style={{ textAlign: 'left', marginTop: 0, color: '#4f4f4f' }}
                >
                  <div className="div-user-button">{buttonIngresar}</div>
                </Title>
                &nbsp;&nbsp;&nbsp;
                {UserAvatar}
              </ul>
            </nav>
          </div>
        )}

        <div style={{ marginTop: 91 }}>
          <Outlet />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Main;

import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, Outlet, useLocation, Link } from 'react-router-dom';

import AuthService from '../services/auth.service';
import logo from '../asset/images/logo_white.png';
import logoCondusef from '../asset/images/logoCONDUSEF.png';
import logoBuro from '../asset/images/security-buro.png';
import logoBc from '../asset/images/buro_credito.png';
import avisoDespacho from '../asset/images/aviso_despachos.jpg';

import './Condusef.css';

import { ComponentesContext } from '../contextos/componentesContext';

import tablaSociedades from '../asset/images/informacion_buro_septiembre_2023.png';
import formatoBuro from '../asset/images/modeloavisoMODAVISO_Finamo_page-0001.jpg';

import { Row, Col, Collapse } from 'antd';

const { Panel } = Collapse;

import {
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  CheckOutlined,
} from '@ant-design/icons';

const AvisoPrivacidad = () => {
  const {
    UpdateFooterText,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    ShowLoading,
    UpdatecurrentStep,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ModalMessage,
    CloseSession,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    UpdateFlagMenuInicial,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth - 60,
    height: window.innerHeight - 190,
    heightScroll: window.innerHeight - 190,
  });

  const LogosStyle = {
    color: 'white',
    fontSize: 30,
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    if (usr === null || usr === undefined) {
      UpdatebuttonIngresar('create');
      UpdateuserAvatar('');
      UpdateFlagtimeLineFormProductos(false);
    } else {
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');

      UpdateFlagtimeLineFormProductos(true);
    }
    InicializaFormulario();
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth - 60,
        height: window.innerHeight - 190,
        heightScroll: window.innerHeight - 190,
      });
    };

    UpdatebuttonIngresar('');
    UpdateuserAvatar('');
    UpdateFlagtimeLineForm(false);
    UpdateFlagtimeLineFormAval(false);
    UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
    UpdateFlagMenuInicial(true);

    window.scrollTo({
      top: 0,
      
    });

    window.addEventListener('resize', handleResize);

    //window.addEventListener("scroll", handleScroll);
    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const InicializaFormulario = () => {};

  const onChange = (key) => {
    //console.log(key);
  };

  const panelStyle = {
    marginBottom: 24,
    border: 'none',
  };

  const handleNext = (option) => {
    navigate(option);
    //window.location.reload();
  };

  return (
    <div
      style={{
        maxHeight: windowSize.heightScroll,
        height: windowSize.heightScroll,
        backgroundColor: '#fff',
        marginTop: 5,
        borderRadius: 15,
      }}
    >
      <div style={{ paddingLeft: 100, paddingRight: 100 }}>
        <center>
          <br></br>
          <br></br>
          <div
            style={{
              fontSize: 40,
              fontWeight: 'bold',
              color: '#4c4cbc',
              marginBottom: 20,
            }}
          >
            CONDUSEF
          </div>
        </center>

        <div>
          <h5 class="div-condusef-title">
            ¿Qué es el Buró de Entidades Financieras?
          </h5>
          <p class="div-condusef-text" style={{ marginTop: -20 }}>
            Es una herramienta de consulta y difusión con la que podrás conocer
            los productos que ofrecen las entidades financieras, sus comisiones
            y tasas, las reclamaciones de los usuarios, las prácticas no sanas
            en que incurren, las sanciones administrativas que les han impuesto,
            las cláusulas abusivas de sus contratos y otra información que
            resulte relevante para informarte sobre su desempeño. Con el Buró de
            Entidades Financieras, se logrará saber quién es quién en bancos,
            seguros, sociedades financieras de objeto múltiple, cajas de ahorro,
            afores, entre otras entidades. Con ello, podrás comparar y evaluar a
            las entidades financieras, sus productos y servicios y tendrás
            mayores elementos para elegir lo que más te convenga. Esta
            información te será útil para elegir un producto financiero y
            también para conocer y usar mejor los que ya tienes.
          </p>

          <p class="div-condusef-text">
            Este Buró de Entidades Financieras, es una herramienta que puede
            contribuir al crecimiento económico del país, al promover la
            competencia entre las instituciones financieras; que impulsará la
            transparencia al revelar información a los usuarios sobre el
            desempeño de éstas y los productos que ofrecen y que va a facilitar
            un manejo responsable de los productos y servicios financieros al
            conocer a detalle sus características. Lo anterior, podrá derivar en
            un mayor bienestar social, porque al conjuntar en un solo espacio
            tan diversa información del sistema financiero, el usuario tendrá
            más elementos para optimizar su presupuesto, para mejorar sus
            finanzas personales, para utilizar correctamente los créditos que
            fortalecerán su economía y obtener los seguros que la protejan,
            entre otros aspectos.
          </p>

          <p class="div-condusef-text">
            Información de{' '}
            <b>ADMINISTRADORA DE SOLUCIONES SA DE CV SOFOM ENR</b> en el Buro de
            Entidades Financieras:
          </p>
        </div>

        <center>
          <img
            src={tablaSociedades}
            style={{ width: windowSize.width - 200, marginTop: 30 }}
          ></img>
        </center>

        <div style={{ textAlign: 'center' }}>
          <p class="div-condusef-text">
            La información corresponde únicamente a
          </p>
          <p
            class="div-condusef-text"
            style={{ fontWeight: 'bold', marginTop: -10 }}
          >
            ADMINISTRADORA DE SOLUCIONES SA DE CV SOFOM ENR,
          </p>
          <p class="div-condusef-text" style={{ marginTop: -10 }}>
            para conocer la información de todo el sector visitar el sitio:
          </p>
          <p class="div-condusef-text" style={{ marginTop: -10 }}>
            https://www.buro.gob.mx/
          </p>
        </div>

        <br></br>
        <div
          class="div-condusef-title"
          style={{ textAlign: 'center', fontWeight: 'bold', marginTop: -20 }}
        >
          <p>Unidad Especializada de Atención a Usuarios (UNE) de Fínamo</p>
        </div>
        <center>
          <img
            src={formatoBuro}
            style={{ width: windowSize.width - 500 }}
          ></img>
        </center>

        <div style={{ marginLeft: 0 }}>
          <p style={{ fontSize: 24, fontWeight: 'bold' }}>CONDUSEF</p>
          <p class="div-condusef-text" style={{ marginTop: -10 }}>
            Comisión Nacional para la Protección y Defensa de los Usuarios de
            Servicios Financieros (Condusef)
          </p>
          <p class="div-condusef-text" style={{ marginTop: -10 }}>
            Avenida Insurgentes Sur 762, Colonia del Valle, alcaldía Benito
            Juárez, código postal 03100, Ciudad de México.
          </p>
          <p class="div-condusef-text" style={{ marginTop: -10 }}>
            Correo electrónico: asesoria@condusef.gob.mx.
          </p>
          <p class="div-condusef-text" style={{ marginTop: -10 }}>
            Teléfono: 55 5340 0999
          </p>
          <p class="div-condusef-text" style={{ marginTop: -10 }}>
            Si lo prefieres, consulta la página web https://www.gob.mx/condusef
          </p>
        </div>

        <div>
          <br></br>
          <br></br>
          <center>
            <Collapse
              accordion
              bordered={false}
              onChange={onChange}
              style={{ marginBottom: 80 }}
              collapsible="header"
            >
              <Panel
                header={
                  <div className="div-collapse-title">
                    Despachos de Cobranza
                  </div>
                }
                key="1"
              >
                <img src={avisoDespacho}></img>
              </Panel>
              <Panel
                header={<div className="div-collapse-title">Comisiones</div>}
                key="2"
              >
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                  style={{ borderRadius: 15 }}
                >
                  <tbody>
                    <tr
                      style={{
                        backgroundColor: 'rgb(76, 76, 188)',
                        color: 'white',
                      }}
                    >
                      <td
                        colSpan="8"
                        style={{
                          borderRadius: '8px 8px 0px 0px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        ADMINISTRADORA DE SOLUCIONES SA DE CV SOFOM ENR
                      </td>
                    </tr>

                    <tr
                      style={{
                        backgroundColor: 'rgb(76, 76, 188)',
                        color: 'white',
                      }}
                    >
                      <td
                        colSpan="8"
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        Información de comisiones
                      </td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: 'rgb(76, 76, 188)',
                        color: 'white',
                      }}
                    >
                      <td style={{ textAlign: 'center' }}>Producto</td>
                      <td style={{ textAlign: 'center' }}>RECA</td>
                      <td style={{ textAlign: 'center' }}>Concepto</td>
                      <td style={{ textAlign: 'center' }}>Porcentaje</td>
                      <td style={{ textAlign: 'center' }}>Periodicidad</td>
                      <td style={{ textAlign: 'center' }}>Tasa de Interes*</td>
                      <td style={{ textAlign: 'center' }}>CAT**</td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: 'rgb(236, 250, 249)',
                        fontSize: 14,
                      }}
                    >
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderLeftWidth: 1,
                          borderLeftStyle: 'solid',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        Crédito simple con tasa variable con obligado solidario
                      </td>
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        3464-439-036128/01-01597-0422
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        Contración o Apertura
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        5% sobre el monto de crédito
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        única vez
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        30%
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        &nbsp;&nbsp;<b>40.80%</b>&nbsp;
                      </td>
                    </tr>

                    <tr
                      style={{
                        fontSize: 14,
                      }}
                    >
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderLeftWidth: 1,
                          borderLeftStyle: 'solid',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        Crédito simple con obligado solidario
                      </td>
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        3464-439-036126/01-01595-0422
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        Contración o Apertura
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        5% sobre el monto de crédito
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        única vez
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        30%
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        &nbsp;&nbsp;<b>40.80%</b>&nbsp;
                      </td>
                    </tr>

                    <tr
                      style={{
                        backgroundColor: 'rgb(236, 250, 249)',
                        fontSize: 14,
                      }}
                    >
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderLeftWidth: 1,
                          borderLeftStyle: 'solid',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        Crédito simple con tasa variable con obligado solidario,
                        garantía fiduciaria
                      </td>
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        3464-439-035915/01-00851-0222
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        Contración o Apertura
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        5% sobre el monto de crédito
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        única vez
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        30%
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        &nbsp;&nbsp;<b>40.80%</b>&nbsp;
                      </td>
                    </tr>

                    <tr
                      style={{
                        fontSize: 14,
                      }}
                    >
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderLeftWidth: 1,
                          borderLeftStyle: 'solid',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        Crédito simple con obligado solidario con garantía
                        fiduciaria
                      </td>
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        3464-439-032519/02-04297-1121
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        Contración o Apertura
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        5% sobre el monto de crédito
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        única vez
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        30%
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        &nbsp;&nbsp;<b>40.80%</b>&nbsp;
                      </td>
                    </tr>

                    <tr
                      style={{
                        backgroundColor: 'rgb(236, 250, 249)',
                        fontSize: 14,
                      }}
                    >
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderLeftWidth: 1,
                          borderLeftStyle: 'solid',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        Crédito simple con garantía hipotecaria y/o prendaria
                      </td>
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        3464-439-025984/03-03067-0617
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        Contración o Apertura
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        5% sobre el monto de crédito
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        única vez
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        30%
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        &nbsp;&nbsp;<b>40.80%</b>&nbsp;
                      </td>
                    </tr>

                    <tr
                      style={{
                        fontSize: 14,
                      }}
                    >
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderLeftWidth: 1,
                          borderLeftStyle: 'solid',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        Factoraje financiero con cesión de derechos de cobro
                      </td>
                      <td
                        style={{
                          paddingLeft: 10,
                          borderColor:
                            'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                          borderRightWidth: 1,
                          borderRightStyle: 'solid',
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                        }}
                      >
                        3464-425-026056/05-03654-0921
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        No aplica
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        No aplica
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        única vez
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        30%
                      </td>
                      <td
                        colSpan="1"
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid rgb(76, 76, 188)',
                          borderBottom: '1px solid rgb(114, 114, 203)',
                        }}
                      >
                        &nbsp;&nbsp;<b>40.80%</b>&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div className="div-collapse-title">Leyendas de Riesgo</div>
                }
                key="3"
              >
                <p style={{ textAlign: 'left' }}>Leyendas de Riesgo:</p>
                <p style={{ textAlign: 'left' }}>
                  • Al ser tu crédito de tasa variable, los intereses pueden
                  aumentar.
                </p>
                <p style={{ textAlign: 'left' }}>
                  • Incumplir con tus obligaciones te puede generar comisiones e
                  intereses moratorios.
                </p>
                <p style={{ textAlign: 'left' }}>
                  • Contratar créditos por arriba de tu capacidad de pago puede
                  afectar tu historial crediticio.
                </p>
                <p style={{ textAlign: 'left' }}>
                  • El avalista, obligado solidario o coacreditado responderá
                  como obligado principal frente a la entidad financiera.
                </p>
              </Panel>

              <Panel
                header={
                  <div className="div-collapse-title">Educación Financiera</div>
                }
                key="4"
              >
                <p style={{ textAlign: 'left' }}>Leyendas de Riesgo:</p>

                <p style={{ textAlign: 'justify' }}>
                  En Fínamo estamos interesados en que nuestros clientes cuenten
                  con una correcta y completa educación financiera que te ayude
                  a tomar las mejores decisiones con el propósito de que
                  utilices de la mejor forma posible los productos y servicios
                  financieros y así tener finanzas más sanas y por lo tanto un
                  crecimiento empresarial y en el aspecto personal una mejor
                  calidad de vida. Es importante el manejo que le des tanto a
                  tus finanzas empresariales como a tus finanzas personales y
                  familiares, te recomendamos que ahorres una parte de tu
                  ingreso para cumplir metas a mediano o largo plazo, pero como
                  sabemos que a veces hay imprevistos y o situaciones en las
                  cuales es necesario una ayuda extra, recuerda que en Fínamo
                  siempre podrás contar con el respaldo económico cuando más lo
                  necesites, el crédito o préstamo es una herramienta que te
                  puede ayudar a salir de esas situaciones no previstas o
                  cumplir metas y esos sueños que te has propuesto.
                </p>

                <p style={{ textAlign: 'justify' }}>
                  Fínamo es una Sociedad Financiera de Objeto Múltiple, las
                  cuales se encuentran reguladas por la CONDUSEF y la cual
                  establece diversas obligaciones que todas las SOFOMS debemos
                  cumplir en beneficio de que tú tomes la mejor decisión a la
                  hora de contratar un crédito en el sector financiero.
                  Infórmate de las opciones que tienes, es importante que cuando
                  solicites un crédito pidas que se te expliquen a detalle,
                  cuánto vas a pagar, cuándo, por cuánto tiempo y demás
                  características relevantes del crédito para que puedas
                  determinar si el crédito que más te conviene y no te sobre
                  endeudes. Cumplir con tus obligaciones de pago es importante
                  ya que así evitarás pagar intereses moratorios y poner en
                  riesgo la economía empresarial, existen créditos que el pago
                  se realiza mediante domiciliación, eso ayudará a que cumplas
                  en tiempo con tu obligación de pago.
                </p>

                <p style={{ textAlign: 'justify' }}>
                  En nuestra página iremos subiendo y actualizando contenido que
                  te ayudará a tomar la mejor decisión financiera para ti y los
                  tuyos, para mayor información sobre educación financiera
                  puedes consultar el sitio de CONDUSEF para tal propósito en la
                  siguiente liga:
                  https://webappsos.condusef.gob.mx/EducaTuCartera/index.html
                </p>

                <p style={{ textAlign: 'justify' }}>
                  Cualquier duda o aclaración te puedes comunicar con nosotros a
                  nuestro número de Atención a Clientes y recuerda que en Fínamo
                  tienes un aliado para impulsar y transformar tu empresa.
                </p>
              </Panel>
            </Collapse>
          </center>
        </div>
      </div>

      <div className="contenedor-imagen">
        <div className="fondo">{/* Tu imagen de fondo */}</div>
        <div className="overlay-imagen">
          {/* Tu contenido dentro del overlay (puedes agregar más elementos aquí) */}
          <Row style={{ width: '100%', marginTop: 20 }}>
            <Col flex="1 1 150px">
              <img className="logo-footer" src={logo}></img>
            </Col>
            <Col flex="1 1 300px" offset={8}>
              <FacebookOutlined style={LogosStyle} />
              &nbsp;&nbsp;
              <TwitterOutlined style={LogosStyle} />
              &nbsp;&nbsp;
              <LinkedinOutlined style={LogosStyle} />
              &nbsp;&nbsp;
              <YoutubeOutlined style={LogosStyle} />
              &nbsp;&nbsp;
              <InstagramOutlined style={LogosStyle} />
              &nbsp;&nbsp;
            </Col>
          </Row>

          <Row style={{ width: '100%' }}>
            <Col flex="0.3 1 150px">
              <div
                className="div-footer-adress"
                style={{ color: 'white', marginLeft: 20 }}
              >
                Blvd. Alfonso G. Calderón Velarde, No. 2656 int. 11 Desarrollo
                Urbano Tres Ríos, C.P. 80020, Culiacán, Sin.
              </div>
            </Col>
          </Row>

          

          <center>
            <Row
              justify="center"
              className='div-logo-links'
            >
              <Col flex="1 1 150px">
                <a
                  onClick={() => handleNext('/aviso_privacidad')}
                  className="footer-link"
                >
                  Aviso de privacidad
                </a>
              </Col>
              <Col flex="1 1 150px">
                <a
                  onClick={() => handleNext('/terminos_condiciones')}
                  className="footer-link"
                >
                  Términos y condiciones
                </a>
              </Col>
              <Col flex="1 1 150px">
                <a
                  onClick={() => handleNext('/conducef')}
                  className="footer-link"
                >
                  CONDUSEF
                </a>
              </Col>
            </Row>
          </center>

          <center>
            <Row justify="center" style={{ width: 350, marginTop: 40 }}>
              <Col flex="1 1 100px">
                <img src={logoCondusef} style={{ width: 100 }}></img>
              </Col>
              <Col flex="1 1 100px">
                <img src={logoBuro} style={{ width: 100 }}></img>
              </Col>
              <Col flex="1 1 100px">
                <img src={logoBc} style={{ width: 100 }}></img>
              </Col>
            </Row>

            {/* <div
              style={{
                width: '90%',
                color: 'white',
                fontSize: 11,
                marginTop: 10,
              }}
            >
              ADMINISTRADORA DE SOLUCIONES, S.A. de C.V., SOFOM, E.N.R. en
              cumplimiento a lo establecido en la Ley General de Organizaciones
              y Actividades Auxiliares del Crédito vigente, indica que para su
              constitución y operación con el carácter de Sociedad Financiera de
              Objeto Múltiple Entidad No Regulada, no requiere de autorización
              de la Secretaría de Hacienda y Crédito Público, y que para la
              realización de sus operaciones está sujeta a la supervisión de la
              Comisión Nacional Bancaria y de Valores, únicamente para efectos
              de lo dispuesto por el artículo 56 de la Ley referida.
            </div>

            <div
              style={{
                width: '90%',
                color: 'white',
                fontSize: 11,
                marginTop: 5,
              }}
            >
              Costo Anual Total (CAT) promedio del producto 40.8% sin IVA. Tasa
              de interés promedio ponderada anual 30%. Tasa fija. Fecha de
              cálculo: 07/06/22. El cálculo del CAT es para fines informativos y
              de comparación. Crédito sujeto a aprobación. Fínamo no lleva a
              acabo el ofrecimiento de productos y/o servicios financieros de
              otras entidades financieras.
            </div> */}
          </center>
        </div>
      </div>
    </div>
  );
};

export default AvisoPrivacidad;

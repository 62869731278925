import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function TerminosCondiciones() {
  return (
    <div
      style={{
        backgroundColor: '#fff',
        marginTop: 5,
        borderRadius: 15,
        padding: 20,
        overflow: 'auto',
        maxHeight: window.innerHeight -300
      }}
    >
      <div style={{ paddingLeft: 20, paddingRight: 20, overflow: 'auto'}}>
        
      <center>
          <div
            style={{
              fontSize: 30,
              fontWeight: 900,
              color: '#4c4cbc',
              marginBottom: 20,
            }}
          >
            Términos y condiciones
          </div>
        </center>

        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <p>
            Administradora de Soluciones, S.A. de C.V., SOFOM, E.N.R., en lo
            sucesivo <b>(Fínamo)</b> con domicilio en Alfonso G. Calderón
            Velarde 2656, Int. 11, Col. Desarrollo Urbano Tres Ríos C.P. 80020
            Culiacán de Rosales, Culiacán, Sinaloa por este medio, da a conocer
            los Términos y Condiciones generales de uso de su Página Web (en
            adelante “Página”).
          </p>
          <p>
            La sola utilización de la Página cuyo dominio es{' '}
            <b>www.finamo.mx</b> o cualquier otra Página o dominio de
            titularidad de Fínamo, le confiere a cualquier persona que ingrese,
            la calidad de Usuario.
          </p>
          <p>
            Los presentes Términos (en lo sucesivo “Términos y Condiciones”)
            tienen carácter obligatorio y vinculante, por lo cual el acceso a la
            Página implica la aceptación plena e incondicional de todas y cada
            una de las condiciones incluidas en los presentes, esto de
            conformidad con lo establecido en el artículo 80 del Código de
            Comercio, en concordancia con el artículo 1803 del Código Civil
            Federal.
          </p>
          <p>
            Todo Usuario deberá abstenerse de utilizar la Página, en caso de no
            aceptar los Términos y Condiciones.
          </p>
          <p>
            Fínamo podrá modificar, actualizar, extender o de cualquier otra
            forma cambiar el contenido y alcance de los presentes Términos y
            Condiciones, en cualquier momento y bajo nuestra completa
            discreción.
          </p>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>Uso de la Página</h4>

          <p>
            El uso de la Página, la información y contenido, así como los
            productos y servicios que en ellas se ofrecen, estarán disponibles
            únicamente para Usuarios que cuenten con capacidad legal para
            contratar y obligarse de conformidad con lo establecido en el Código
            Civil Federal; por lo cual, aquellos Usuarios que no cumplan con
            esta condición deberán abstenerse de acceder al sitio. En caso de
            que el Usuario no cumpla con lo anterior, deslinda a Fínamo de
            cualquier responsabilidad sobre la información suministrada y la
            contratación de los productos y/o servicios que realice en la
            Página.
          </p>
          <p>
            Fínamo, en cualquier momento, dejar sin efectos los presentes
            Términos y Condiciones, sea de manera general o particular, así como
            dejar de ofrecer los productos y servicios en su Página sin
            necesidad de notificación alguna y sin que medie justificación.
          </p>
          <p>
            El acceso a y uso de algunos de los productos o servicios que se
            contienen en la Página podrá ser condicionado a previa contratación,
            activación o registro, según se informe por Fínamo al Usuario en las
            condiciones particulares de cada producto o servicio. Los presentes
            términos y condiciones no podrán considerarse como la aceptación del
            otorgamiento de un crédito y/o producto financiero, por lo que,
            Fínamo no se encuentra obligado a realizar una oferta vinculante que
            tenga como finalidad el otorgamiento de alguno de sus servicios y se
            encuentra sujeto a que se cumplan con los requisitos para el
            otorgamiento del crédito y/o producto o servicio financiero
            correspondiente.
          </p>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>Página WEB</h4>
          <p>
            La información mostrada en la Página es meramente con fines
            informativos. El material que se encuentra disponible está sujeto a
            cambios sin previo aviso.
          </p>
          <p>
            Fínamo realizará su mejor esfuerzo para mantener la Página operando
            correctamente y libre de virus electrónicos, u otras restricciones,
            sin embargo, Fínamo por ningún motivo garantiza la ausencia de
            virus, errores o cualquier otro material contaminante o con
            funciones destructivas en la información o programas disponibles en
            o a través de la Página, siendo, por ende, exclusiva responsabilidad
            del Usuario el contar con los antivirus, firewalls y demás sistemas
            de seguridad electrónica.
          </p>
          <p>
            El acceso a la Página puede, en ocasiones, ser limitado o lento, o
            verse afectado debido a una serie de factores, que de manera
            enunciativa, más no limitativa son: servidores, redes, fallas en el
            hardware (incluyendo el dispositivo que use el Cliente y/o Usuario),
            líneas y conexiones de telecomunicaciones y otros equipos
            electrónicos y mecánicos; falla del software, que incluye, entre
            otras cosas, errores, virus, problemas de configuración,
            incompatibilidad de sistemas, utilidades o aplicaciones, el
            funcionamiento de cortafuegos o programas de detección, códigos
            ilegibles o irregularidades dentro de documentos particulares u otro
            contenido; sobrecarga de capacidades del sistema; así como cualquier
            otra causa, por lo cual Fínamo, no será responsable de los daños y
            perjuicios que sufra el Usuario derivado de tales supuestos.
          </p>
          <p>
            El Usuario reconoce y acepta que la Página, podrá utilizar Cookies
            y/o programas o herramientas para conocimiento del cliente, y
            verificación de identidad.
          </p>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>Usos Permitidos</h4>
          <p>
            El Usuario se obliga a utilizar la Página exclusivamente para las
            funcionalidades permitidas de cada producto o servicio y contenido
            respectivo, y siguiendo la legislación aplicable, estos Términos y
            Condiciones, los principios de buena fe y los usos generalmente
            aceptados. Cualquier uso con fines ilícitos o presuntamente ilícitos
            que perjudiquen, limiten o impidan de cualquier forma, la
            utilización y normal funcionamiento de la Página, o bien, que
            directa o indirectamente atente contra su uso normal, sea en
            perjuicio del propio Usuario, de Fínamo o de cualquier tercero,
            queda prohibido.
          </p>
          <p>
            Si está disponible la generación, inclusión o publicación de
            comentarios en blogs, foros o muros de la Página, el Usuario no
            deberá introducir comentarios que puedan resultar ofensivos,
            infamantes, injuriosos o calumniosos, contrarios a la moral, el
            orden público, o a la dignidad de las personas, o que de cualquier
            otra manera puedan resultar falsos, ilícitos o ilegales o causar un
            daño o perjuicio a terceros o a Fínamo.
          </p>
          <p>
            Fínamo se reserva el derecho de retirar inmediatamente el acceso al
            Usuario a la Página cuando identifique que el Usuario incumple con
            estos Términos y Condiciones y/o con el Aviso de Privacidad.
          </p>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>Propiedad Intelectual</h4>
          <p>
            La Página contiene derechos de propiedad intelectual de titularidad
            exclusiva de Fínamo, debidamente protegidos por la Ley de Propiedad
            Industrial y Ley Federal de Derecho de Autor, según corresponda.
          </p>
          <p>
            La aceptación de los presentes Términos y Condiciones no implica el
            otorgamiento de una licencia o cualquier otro título a favor del
            Usuario que le permita utilizar las marcas, nombres y avisos
            comerciales, denominaciones de productos o cualquier otro derecho de
            propiedad industrial o intelectual perteneciente a Fínamo.
          </p>
          <p>
            El Usuario se compromete a no violar, reproducir, imitar, total o
            parcialmente, cualquier propiedad intelectual de Fínamo, así como a
            no utilizar ninguna propiedad intelectual de Fínamo para fines
            distintos de los previstos en estos Términos y Condiciones y
            permitidos por la legislación aplicable.
          </p>
          <p>
            Al registrarse como Usuario y utilizar los Servicios proporcionados
            a través de la Página, cada Usuario concede a Fínamo una
            autorización a título gratuito, irrevocable y sin limitación
            territorial para utilizar, copiar, licenciar, publicar, reproducir,
            transmitir, modificar o de cualquier otra forma usar por todos los
            medios conocidos o que se desarrollen en el futuro, incluyendo el
            uso promocional o con fines publicitarios, aquella información o
            datos personales proporcionados por estos últimos, atendiendo en
            todo momento a los establecido en el aviso de privacidad de Fínamo.
          </p>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>Uso de Cookies</h4>
          <p>
            En la Página, podemos usar cookies funcionales. Las cookies
            utilizadas por Fínamo nos permiten recopilar, analizar y conservar
            información electrónica relacionada con las visitas a la Página. Las
            cookies que usamos permiten recabar esta información de forma
            automática en el momento mismo en que accedes a la Página y a
            nuestros servicios electrónicos; sin embargo, éstas no permiten
            identificar individualmente a las personas que nos visitan. Si el
            Usuario navega en nuestra Página y/o envía información a través del
            Sitio, consideramos que otorga su consentimiento expreso para la
            instalación de nuestras cookies. Para obtener información más
            detallada acerca de las cookies y la forma en que puedes
            deshabilitarlas en función de tu navegador y sistema operativo,
            recomendamos que el Usuario visite el sitio web allaboutcookies.org,
            en el que explican paso a paso cómo deshabilitarlas. El Usuario debe
            tomar en cuenta que, si desactiva las cookies o impide su
            instalación, es posible que no pueda usar de manera completa algunas
            funciones de la Página.
          </p>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>Indemnización</h4>
          <p>
            El Usuario conviene en indemnizar y mantener en paz y a salvo a
            Fínamo, así como a sus filiales, empresas controladas y/o
            controladoras, directivos, administradores, representantes y
            empleados, por cualquier reclamo o demanda de otros Usuarios y/o
            terceros por actividades en la Página, que pudieran violar derechos
            de terceros, así como por el incumplimiento de los presentes
            Términos y Condiciones.
          </p>
        </div>
        <div class="col-12 mb-5" style={{textAlign:"justify"}}>
          <h4>Legislación Aplicable</h4>
          <p>
            Para lo no previsto en estos Términos y Condiciones, los Clientes
            y/o Usuarios acuerdan someterse a las leyes aplicables de los
            Estados Unidos Mexicanos.
          </p>
          <p >
            Para la interpretación y ejecución de los presentes Términos y
            Condiciones, el Usuario acepta someterse a la jurisdicción y
            competencia de los tribunales competentes en la Ciudad de Culiacán,
            Sinaloa, renunciando expresamente al fuero que pudiese
            corresponderle por razón de su domicilio presente o futuro.
          </p>
        </div>
      </div>
    </div>
  );
}

import { createContext, useState } from 'react';
import {
  Typography,
  Spin,
  message,
  notification,
  Modal,
  Button,
  Badge,
  Avatar,
  Steps,
  Dropdown,
} from 'antd';
import AuthService from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import {
  HistoryOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { PlusOutlined, UserOutlined, SendOutlined } from '@ant-design/icons';

import {
  FaBuilding,
  FaCity,
  FaHotel,
  FaMoneyCheckDollar,
  FaMoneyBill1,
  FaMoneyBillWave,
  FaSackDollar,
  FaDollarSign,
  FaUserPlus,
  FaCloud,
  FaComments,
  FaFileArrowUp,
  FaKey,
  FaFileContract,
  FaFlagCheckered,
  FaCircle,
  FaIdCard,
  FaClipboardUser,
  FaLocationDot,
  FaUserDoctor,
  FaFileSignature,
} from 'react-icons/fa6';
import zIndex from '@mui/material/styles/zIndex';

export const ComponentesContext = createContext();

export function ComponentesContextProvider({ children }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notificationsCounter, SetnotificationsCount] = useState(0);
  const [DataLista, setDataLista] = useState([]);
  const [messageList, setmessageList] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [tableHeight, settableHeight] = useState(300);
  const [userType, setuserType] = useState();
  const [userImagenUrl, setuserImagenUrl] = useState();
  const [buttonIngresar, setbuttonIngresar] = useState(<div></div>);
  const [UserAvatar, setUserAvatar] = useState(<div></div>);
  const [currentStep, setcurrentStep] = useState(1);
  const [timeLineForm, settimeLineForm] = useState(<div></div>);
  const [FlagtimeLineForm, setFlagtimeLineForm] = useState(false);
  const [FlagtimeLineFormMovil, setFlagtimeLineFormMovil] = useState(false);

  const [FlagtimeLineFormAval, setFlagtimeLineFormAval] = useState(false);

  const [FlagtimeLineFormProductos, setFlagtimeLineFormProductos] =
    useState(false);
  const [FlagMenuInicial, setFlagMenuInicial] = useState(false);
  const [isModalOpenPLD, setisModalOpenPLD] = useState(false);
  const [FooterText, setFooterText] = useState(
    '© Fínamo 2023. Todos los derechos reservados.'
  );

  const [pendientesAval, setpendientesAval] = useState(false);

  const [usuarioSesion, setusuarioSesion] = useState([]);
  const [movilSteps, setmovilSteps] = useState(<div></div>);
  const [tipoDocumentoSeleccionado, settipoDocumentoSeleccionado] = useState(0);
  const [intSolicitudId, setintSolicitudId] = useState(0);

  const [EmailIngresado, setEmailIngresado] = useState('');

  // const [titleState0, settitleState0] = useState("");
  // const [titleState1, settitleState1] = useState("");
  // const [titleState2, settitleState2] = useState("");
  // const [titleState3, settitleState3] = useState("");
  // const [titleState4, settitleState4] = useState("");

  const { confirm } = Modal;

  const InputTypes = {
    Text: 0,
    Integer: 1,
    Decimal: 2,
    Rfc: 3,
    Curp: 4,
  };

  const RfcTypes = {
    Fisica: 0,
    Moral: 1,
  };

  const openNotification = (content, placement) => {
    api.open({
      message: (
        <>
          <CheckCircleOutlined />
          &nbsp;Mensaje
        </>
      ),
      description: (
        <Typography.Title level={3} style={{ margin: 0, color: '#081b3e' }}>
          &nbsp;&nbsp;&nbsp;{content}
        </Typography.Title>
      ),
      className: 'custom-class',
      style: {
        width: 600,
      },
      placement,
    });
  };

  const ShowLoading = (flagLoading) => {
    setLoading(flagLoading);
  };

  const UpdateNotificationsCounter = (count) => {
    SetnotificationsCount(count);
  };

  const UpdateDataLista = (data) => {
    setDataLista(data);
  };

  const UpdatemessageList = (data) => {
    setmessageList(data);
  };

  const UpdatesettableHeight = (height) => {
    settableHeight(height);
  };

  const UpdatesetuserType = (type) => {
    setuserType(type);
  };

  const UpdatesetuserImagenUrl = (type) => {
    userImagenUrl(type);
  };

  const handleNext = (option) => {
    navigate(option);
    //window.location.reload();
  };

  const UpdateFooterText = (text) => {
    setFooterText(text);
  };

  const UpdateFlagMenuInicial = (val) => {
    setFlagMenuInicial(val);
  };

  const UpdateEmailIngresado = (email) => {
    setEmailIngresado(email);
  };

  const UpdatebuttonIngresar = (viewName, user = '') => {
    if (viewName == 'create') {
      setbuttonIngresar(
        <Button
          type="primary"
          size={'default'}
          style={{
            backgroundColor: '#ffffff',
            color: '#4c4cbc',
            height: 40,
            fontWeight: 'bold',
            boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.35)',
            marginRight: 20,
            marginTop: 10,
          }}
          onClick={() =>
            navigate('/login', {
              state: { record: { correo: '' } },
            })
          }
          className="account-welcome-btn-create"
        >
          <UserOutlined style={{ fontSize: '18px' }} />
          {' Ingresar '}
        </Button>
      );
    } else if (viewName == 'login') {
      setbuttonIngresar(
        <Button
          type="primary"
          size={'default'}
          style={{
            backgroundColor: '#ffffff',
            color: '#4c4cbc',
            height: 40,
            fontWeight: 'bold',
            boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.35)',
            marginRight: 20,
            marginTop: 10,
          }}
          onClick={() => handleNext('/create')}
          className="account-welcome-btn-create"
        >
          <PlusOutlined style={{ fontSize: '18px' }} />
          {' Crear Cuenta '}
        </Button>
      );
    } else if (viewName == 'dashboard') {
      setbuttonIngresar(
        <div>
          <div style={{ color: '#4c4cbc', fontWeight: 'bold', minWidth: 100 }}>
            Hola:
          </div>
          <div style={{ fontSize: 13 }}>{user}</div>
        </div>
      );
    } else {
      setbuttonIngresar(<div></div>);
    }
  };

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const logOut = () => {
    AuthService.logout();
    navigate('/login', {
      state: { record: { correo: '' } },
    });
  };

  const cambioContrasena = () => {
    //AuthService.logout();
    let usr = AuthService.getCurrentUser();
    navigate('/cambio_password', {
      state: { record: { UsuarioId: usr.id } },
    });
  };

  const items = [
    getItem(<a onClick={(e) => logOut()}>Salir</a>, '4', <ExportOutlined />),
    getItem(
      <a onClick={(e) => cambioContrasena()}>Cambiar contraseña</a>,
      '4',
      <FaKey />
    ),
  ];

  const UpdateuserAvatar = (viewName) => {
    if (viewName == 'dashboard') {
      setUserAvatar(
        <Dropdown
          menu={{ items }}
          placement="bottomRight"
          style={{ marginRight: 20 }}
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <span>
              <Badge size="small" count={0}>
                <Avatar
                  className="component-avatar"
                  style={{ backgroundColor: '#4c4cbc', marginRight: 20 }}
                  size={45}
                  icon={<UserOutlined />}
                ></Avatar>
              </Badge>
            </span>
          </a>
        </Dropdown>
      );
    } else {
      setUserAvatar(<div></div>);
    }
  };

  const handleCloseSession = async (values) => {
    let usr = AuthService.getCurrentUser();
    //console.log(usr);
    AuthService.logout();

    navigate('/login', {
      state: { record: { correo: usr.email } },
    });
    //navigate("/login");
  };

  const CloseSession = () => {
    message.warning(
      <Typography.Title level={3} style={{ margin: 0, color: '#081b3e' }}>
        Su sesión ha expirado. La página se redirigirá al inicio de sesión.
      </Typography.Title>,
      3,
      handleCloseSession
    );
  };

  const ModalMessage = (titulo, contenido) => {
    Modal.info({
      title: titulo,
      content: contenido,
      onOk() {},
    });
  };

  const UpdatecurrentStep = (step) => {
    setcurrentStep(step);
  };

  const UpdateTipoDocumentoSeleccionado = (tipo) => {
    settipoDocumentoSeleccionado(tipo);
  };

  const UpdateintSolicitudId = (solicitudId) => {
    setintSolicitudId(solicitudId);
  };

  const UpdatesetisModalOpenPLD = (flag) => {
    setisModalOpenPLD(flag);
  };

  const handleRegresaColorStep = (step) => {
    if (currentStep < step) {
      return '#d3dfe4';
    } else if (currentStep > step) {
      return '#04bb73';
    } else {
      return '#4c4cbc';
    }
  };

  const handleTamanoStep = (step) => {
    if (currentStep < step) {
      return 30;
    } else if (currentStep > step) {
      return 30;
    } else {
      return 30;
    }
  };

  const UpdateFlagtimeLineForm = (flag) => {
    setFlagtimeLineForm(flag);
  };

  const UpdateFlagtimeLineFormMovil = (flag) => {
    setFlagtimeLineFormMovil(flag);
  };

  const UpdateFlagtimeLineFormProductos = (flag) => {
    setFlagtimeLineFormProductos(flag);
  };

  const UpdateUserSesion = (data) => {
    setusuarioSesion(data);
  };

  const UpdatePendientesAval = (flag) => {
    setpendientesAval(flag);
  };

  const UpdateFlagtimeLineFormAval = (flag) => {
    setFlagtimeLineFormAval(flag);
  };

  const ReturnStepCustom = (step, numIcon) => {
    //console.log("Actual", numIcon);

    if (numIcon < step) {
      return <FaCircle style={{ color: '#d3dfe4', marginLeft: 5 }} />;
    } else if (numIcon > step) {
      return <FaCircle style={{ color: '#04bb73', marginLeft: 5 }} />;
    } else {
      switch (numIcon) {
        case 0:
          return (
            <FaMoneyCheckDollar
              style={{ color: '#4c4cbc', marginLeft: 5, fontSize: 30 }}
            />
          );
        case 1:
          return (
            <FaUserDoctor
              style={{ color: '#4c4cbc', marginLeft: 5, fontSize: 30 }}
            />
          );
        case 2:
          return (
            <FaBuilding
              style={{ color: '#4c4cbc', marginLeft: 5, fontSize: 30 }}
            />
          );
        case 3:
          return (
            <FaLocationDot
              style={{ color: '#4c4cbc', marginLeft: 5, fontSize: 30 }}
            />
          );
        case 4:
          return (
            <FaClipboardUser
              style={{ color: '#4c4cbc', marginLeft: 5, fontSize: 30 }}
            />
          );
        case 5:
          return (
            <FaIdCard
              style={{ color: '#4c4cbc', marginLeft: 5, fontSize: 30 }}
            />
          );
        case 6:
          //return (<FaFileArrowUp style={{ color: "#4c4cbc", marginLeft: 5, fontSize: 30 }} />);
          //return (<FaComments style={{ color: "#4c4cbc", marginLeft: 5, fontSize: 30 }} />);
          return (
            <FaCloud
              style={{ color: '#4c4cbc', marginLeft: 5, fontSize: 30 }}
            />
          );
        case 7:
          //return (<FaCloud style={{ color: "#4c4cbc", marginLeft: 5, fontSize: 30 }} />);
          //return (<FaComments style={{ color: "#4c4cbc", marginLeft: 5, fontSize: 30 }} />);
          return (
            <FaFileSignature
              style={{ color: '#4c4cbc', marginLeft: 5, fontSize: 30 }}
            />
          );
        case 8:
          return (
            <FaFlagCheckered
              style={{ color: '#4c4cbc', marginLeft: 5, fontSize: 30 }}
            />
          );
        //return (<FaFlagCheckered style={{ color: "#4c4cbc", marginLeft: 5, fontSize: 30 }} />);
        //return (<FaKey style={{ color: "#4c4cbc", marginLeft: 5, fontSize: 30 }} />);
        //return (<FaCloud style={{ color: "#4c4cbc", marginLeft: 5, fontSize: 30 }} />);
        //case 9:
        //return (<FaFlagCheckered style={{ color: "#4c4cbc", marginLeft: 5, fontSize: 30 }} />);
        // case 10:

        default:
          return <FaCircle style={{ color: '#4c4cbc', marginLeft: 5 }} />;
      }
    }
  };

  const UpdatemovilSteps = (step) => {
    const numSteps = 9;
    setmovilSteps(
      <div>
        {' '}
        {Array.from({ length: numSteps }, (_, index) =>
          ReturnStepCustom(index, step)
        )}{' '}
      </div>
    );
  };

  return (
    <ComponentesContext.Provider
      value={{
        CloseSession,
        ShowLoading,
        InputTypes,
        openNotification,
        notificationsCounter,
        UpdateNotificationsCounter,
        DataLista,
        UpdateDataLista,
        messageList,
        UpdatemessageList,
        tableHeight,
        UpdatesettableHeight,
        ModalMessage,
        userType,
        UpdatesetuserType,
        userImagenUrl,
        UpdatesetuserImagenUrl,
        buttonIngresar,
        UpdatebuttonIngresar,
        UserAvatar,
        UpdateuserAvatar,
        currentStep,
        UpdatecurrentStep,
        FlagtimeLineForm,
        UpdateFlagtimeLineForm,
        usuarioSesion,
        UpdateUserSesion,
        movilSteps,
        UpdatemovilSteps,
        RfcTypes,
        tipoDocumentoSeleccionado,
        UpdateTipoDocumentoSeleccionado,
        UpdateintSolicitudId,
        intSolicitudId,
        UpdateFlagtimeLineFormMovil,
        FlagtimeLineFormMovil,
        FlagtimeLineFormProductos,
        UpdateFlagtimeLineFormProductos,
        isModalOpenPLD,
        UpdatesetisModalOpenPLD,
        FooterText,
        UpdateFooterText,
        FlagMenuInicial,
        UpdateFlagMenuInicial,
        EmailIngresado,
        UpdateEmailIngresado,
        pendientesAval,
        UpdatePendientesAval,
        FlagtimeLineFormAval,
        UpdateFlagtimeLineFormAval,
      }}
    >
      {contextHolder}
      <Spin size="large" spinning={loading}>
        {children}
      </Spin>
    </ComponentesContext.Provider>
  );
}

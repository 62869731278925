
import FormularioService from '../services/formularios.Service';

const RefreshAvalType = async () => {

  let respuestaAval = await FormularioService.getSolicitudesPendientesAval();

  //console.log(respuestaAval);

  if (respuestaAval.estado == 500) {
    return false;
  }

  if (respuestaAval.data.length > 0) {
    return true;
  } else {
    return false;
  }

  return false;
};
const RefreshAvalTypeClass = {
  RefreshAvalType,
};

export default RefreshAvalTypeClass;

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ComponentesContext } from '../../../contextos/componentesContext';
import {
  Button,
  Select,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  InputNumber,
} from 'antd';

import {
  Slider,
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  TextField,
  Autocomplete,
  Input,
} from '@mui/material';

import InputMask from 'react-input-mask';

import { ArrowRightOutlined } from '@ant-design/icons';
import '../../Welcome.css';
import './ConfigurarCredito.css';

import AuthService from '../../../services/auth.service';
import FormulariosService from '../../../services/formularios.Service';

import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const ConfigurarCredito = () => {
  const {
    UpdateFooterText,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    ShowLoading,
    UpdatecurrentStep,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ModalMessage,
    CloseSession,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    UpdateFlagMenuInicial,
    UpdateFlagtimeLineFormAval,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [tipoArrendamientoMedico, SettipoArrendamientoMedico] = useState(
    typeof location.state.record.tipoArrendamientoMedico != 'undefined'
      ? location.state.record.tipoArrendamientoMedico != null
        ? location.state.record.tipoArrendamientoMedico
        : '0'
      : '0'
  );

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightScroll: (window.innerHeight * 60) / 100,
  });

  const [Periodos, setPeriodos] = useState([
    {
      value: 12,
      label: '12',
    },
    {
      value: 24,
      label: '24',
    },
    {
      value: 36,
      label: '36',
    },
  ]);

  const marks = [
    {
      value: 12,
      label: '12',
    },
    {
      value: 24,
      label: '24',
    },
    {
      value: 36,
      label: '36',
    },
  ];

  const [DisableForm, setDisableForm] = useState(true);

  const [montoMinimo, setmontoMinimo] = useState(0);
  const [montoMaximo, setmontoMaximo] = useState(0);
  const [destinoCreditoList, setdestinoCreditoList] = useState([]);
  const [optionsDestino, setoptionsDestino] = useState([]);

  const [PeriodoMinimo, setPeriodoMinimo] = useState(0);
  const [PeriodoMaximo, setPeriodoMaximo] = useState(0);

  const [formMontoInteres, setformMontoInteres] = useState(0);
  const [formPeriodos, setformPeriodos] = useState(0);
  const [formDestinoCredito, setformDestinoCredito] = useState(0);
  const [formMontoPago, setformMontoPago] = useState(0);

  const [intsolicitudId, setintsolicitudId] = useState(0);

  const [
    solicitudTipoArrendamientoMedico,
    setsolicitudTipoArrendamientoMedico,
  ] = useState(0);

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();
      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightScroll: (window.innerHeight * 60) / 100,
        });
      };
      InicializaFormulario();

      UpdateFlagtimeLineForm(true);
      UpdateFlagtimeLineFormMovil(true);
      UpdateFlagtimeLineFormAval(false);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(0);
      UpdatemovilSteps(0);

      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      UpdateFlagtimeLineFormProductos(true);
      UpdateFlagMenuInicial(false);

      window.addEventListener('resize', handleResize);

      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  // 01   INICIALIZA EL FORMULARIO----------------------------------------------------------------------------------------
  const InicializaFormulario = async () => {
    ShowLoading(true);

    let ValidaSesion = await AuthService.getValidaSesion();
    //console.log(ValidaSesion);

    //console.log('Solicitud ID', solicitudId);
    console.log('tipo arrendamiento', tipoArrendamientoMedico);

    var respuesta = {};
    if (solicitudId == 0) {
      //console.log('entro 0');
      respuesta = await FormulariosService.getConfiguraCredito(
        tipoArrendamientoMedico
      );
    } else {
      //console.log("entro diferente 0");
      respuesta =
        await FormulariosService.getConfiguraCreditoRegistrado(solicitudId);
    }

    //console.log('respuesta', respuesta);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();
      if (respuestaSesion.result == false) {
        CloseSession();
      }
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    }

    if (tipoArrendamientoMedico == 0) {
      console.log('entro 0', respuesta.data.solicitudTipoArrendamientoMedico);

      setsolicitudTipoArrendamientoMedico(
        respuesta.data.solicitudTipoArrendamientoMedico
      );
    } else {
      setsolicitudTipoArrendamientoMedico(tipoArrendamientoMedico);
    }

    //setintsolicitudId(respuesta.data.solicitudId);

    setDisableForm(!respuesta.data.habilitado);

    //console.log(respuesta.data.solicitudMonto);

    setformMontoInteres(respuesta.data.solicitudMonto);

    if (respuesta.data.solicitudPeriodo == '') {
      setformPeriodos('12');
    } else {
      setformPeriodos(respuesta.data.solicitudPeriodo);
    }

    setformDestinoCredito(
      respuesta.data.solicitudDestinoArrendamientoMedicoId == 0
        ? ''
        : respuesta.data.solicitudDestinoArrendamientoMedicoId.toString()
    );

    if (respuesta.data.solicitudDestinoArrendamientoMedicoId == 0) {
      var calculo =
        (respuesta.data.solicitudMonto * (0.29 / 12)) /
        (1 - Math.pow(1 + 0.29 / 12, -12));

      const formattedAmount = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      }).format(calculo);

      setformMontoPago(formattedAmount);
    } else {
      const formattedAmount = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      }).format(respuesta.data.solicitudMontoPago);

      setformMontoPago(formattedAmount);
    }

    setmontoMinimo(respuesta.data.montoMinimo);
    setmontoMaximo(respuesta.data.montoMaximo);

    const Arrmarks = {};
    respuesta.data.periodos.forEach((value) => {
      Arrmarks[value] = value;
    });

    const periodos = respuesta.data.periodos.map(Number); // Convertir los valores a números

    //console.log('periodos', Arrmarks);
    //setPeriodos(Arrmarks);
    setPeriodoMinimo(Math.min(...periodos));
    setPeriodoMaximo(Math.max(...periodos));

    const options = respuesta.data.destinoArrendamientoMedicoList.map(
      (item) => ({
        value: item.id.toString(), // Convierte el ID a una cadena
        label: item.nombre,
      })
    );

    //console.log("opciones", options);
    setoptionsDestino(options);

    var strPeriodo = '12';

    if (respuesta.data.solicitudPeriodo != '') {
      strPeriodo = respuesta.data.solicitudPeriodo;
    }

    setTimeout(function () {
      form.setFieldsValue({
        configuraCredito: {
          montoInteres: respuesta.data.solicitudMonto,
          periodoCredito: respuesta.data.solicitudPeriodo,
        },
      });
    }, 0);

    ShowLoading(false);
  };
  // ----------------------------------------------------------------------------------------------------------------

  // 02  GUARDA EL FORMULARIO----------------------------------------------------------------------------------------
  const onFinish = async () => {
    if (formMontoInteres == '') {
      ModalMessage('Mensaje', 'El monto de interés no puede estar en blanco.');
      return false;
    }

    const formattedAmount = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(montoMinimo);

    if (parseInt(formMontoInteres) < parseInt(montoMinimo)) {
      ModalMessage(
        'Mensaje',
        'El monto de interés debe ser mayor a ' + formattedAmount
      );
      return false;
    }

    if (parseInt(formMontoInteres) > parseInt(montoMaximo)) {
      const formattedAmount2 = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      }).format(montoMaximo);

      ModalMessage(
        'Mensaje',
        'El monto de interés no debe ser mayor a ' + formattedAmount2
      );
      return false;
    }

    if (formDestinoCredito == 0) {
      ModalMessage('Mensaje', 'Debes seleccionar el Activo');
      return false;
    }

    const json = {
      solicitudId: solicitudId == '0' ? null : solicitudId.toString(),
      montoSolicitado: formMontoInteres,
      periodos: formPeriodos,
      destinoCreditoId: parseInt(1),
      destinoArrendamientoMedicoId: parseInt(formDestinoCredito),
      montoPagoEstimado: parseFloat(
        formMontoPago
          .toString()
          .substring(1, formMontoPago.toString().length)
          .replace(',', '')
      ),
    };

    ShowLoading(true);

    if (DisableForm == false) {
      let respuestaGuardar =
        await FormulariosService.postGuardaConfiguraCredito(json);
      //console.log("respuesta guardar configuracion", respuestaGuardar.result);
      if (respuestaGuardar.result == true) {
        ShowLoading(false);
        navigate('/datos_cliente', {
          state: { record: { solicitudId: respuestaGuardar.data.id } },
        });
      } else {
        ShowLoading(false);
        ModalMessage('Error', 'Se produjo un error al guardar el formulario.');
        return false;
      }
    } else {
      let respuesta = await FormulariosService.getSolicitudes();
      if (respuesta.result != true) {
        let respuestaSesion = await AuthService.getValidaSesion();
        //console.log('respuesta dash validacion', respuestaSesion);
        if (respuestaSesion.result == false) {
          CloseSession();
          return false;
        }

        ModalMessage('Error', 'Se produjo un error al cargar las solicitudes.');

        return false;
      }

      if (respuesta.data.length > 0) {
        navigate('/datos_cliente', {
          state: { record: { solicitudId: respuesta.data[0].id } },
        });
      }
    }

    ShowLoading(false);
  };
  // ----------------------------------------------------------------------------------------------------------------

  const handleChangeMontoInteres = async (e) => {
    //console.log(e);

    setformMontoInteres(e);

    //console.log(e);

    //console.log('periodos', formPeriodos);

    if (formPeriodos == null) {
      setformPeriodos(12);
    }

    //console.log(formPeriodos);

    var calculo =
      (parseInt(e) * (0.29 / 12)) /
      (1 - Math.pow(1 + 0.29 / 12, -formPeriodos));
    const formattedAmount = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(calculo);

    // console.log(calculo);
    setformMontoPago(formattedAmount);
  };

  const handleChangePeriodos = async (e, value) => {
    //console.log(value);

    setformPeriodos(value);
    var calculo =
      (parseInt(String(formMontoInteres).replace(/[^a-zA-Z0-9 ]/g, '')) *
        (0.29 / 12)) /
      (1 - Math.pow(1 + 0.29 / 12, -value));
    const formattedAmount = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(calculo);

    setformMontoPago(formattedAmount);
  };

  const handleChangeDestinoCredito = async (e) => {
    setformDestinoCredito(e);
  };

  const handleChangeMontoPago = async (e) => {
    //console.log(e.replace(/[^a-zA-Z0-9 ]/g, ''));

    setformMontoInteres(e);
  };

  const handleKeyDownMontoPago = async (e) => {
    e.preventDefault();
  };

  const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
      const { onChange, ...other } = props;

      React.useImperativeHandle(ref, () => ({
        focus: () => {
          // logic to focus the rendered component from 3rd party belongs here
        },
        // hiding the value e.g. react-stripe-elements
      }));

      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator
          valueIsNumericString
          prefix="$"
        />
      );
    }
  );

  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: 450,
      }}
    >
      <div
        className="div-img-arrendamiento"
        style={{
          borderRadius: 25,
          padding: 0,
          boxShadow: '0px 0px 22px -10px rgba(0,0,0,0.75)',
          background: '#fff',
          marginTop: 30,
          width: windowSize.width - 200,
          minHeight: windowSize.height + 20,
          minWidth: 420,
        }}
      >
        <center>
          <Divider>
            <div
              className="title-steps"
              style={{ margin: 0, color: '#4c4cbc', marginTop: 0 }}
            >
              Configura tu arrendamiento
            </div>
          </Divider>

          <div
            className="subtitle-steps"
            style={{
              margin: 0,
              marginTop: 40,
              color: '#404040',
              marginBottom: 20,
            }}
          >
            Capture los siguientes datos:
          </div>
        </center>

        <Skeleton loading={loading} active={true}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Row style={{ width: '90%', marginTop: 8 }}>
              <Col span={24}>
                <TextField
                  label={
                    <>
                      <label>Valor del Activo</label>
                    </>
                  }
                  value={formMontoInteres}
                  onChange={(e) => handleChangeMontoInteres(e.target.value)}
                  //onChange={handleChange}
                  autoFocus={true}
                  name="numberformat"
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  variant="outlined"
                  key="numberMonto"
                  size="small"
                  style={{ width: '100%' }}
                  disabled={DisableForm}
                />

                {/* <InputMask
                  mask="$ 9,999,999"
                  maskChar={null}
                  alwaysShowMask={false}
                  value={formMontoInteres}
                  onChange={(e) => handleChangeMontoInteres(e.target.value)}
                >
                  {(inputProps) => (
                    <TextField
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label>Monto a Financiar</label>
                        </>
                      }
                      {...inputProps}
                      size="small"
                      variant="outlined"
                    />
                  )}
                </InputMask> */}
              </Col>
            </Row>
          </div>

          <center>
            <Row style={{ width: '90%', marginTop: 8 }}>
              <Col span={24}>
                <div style={{ width: '100%', textAlign: 'left' }}>
                  Período de pago:
                </div>
                <Slider
                  value={formPeriodos}
                  min={PeriodoMinimo}
                  max={PeriodoMaximo}
                  marks={Periodos}
                  step={12}
                  onChange={handleChangePeriodos}
                  // defaultValue={12}
                  disabled={DisableForm}
                  sx={{
                    color: '#4c4cbc', // Cambiar el color del track y del thumb
                    '& .MuiSlider-rail': {
                      backgroundColor: '#4c4cbc', // Cambiar el color del track
                    },
                    '& .MuiSlider-thumb': {
                      backgroundColor: '#4c4cbc', // Cambiar el color del thumb
                    },
                  }}
                />
                <div style={{ fontWeight: 'bold' }}>{formPeriodos} Meses</div>
              </Col>
            </Row>
          </center>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Row style={{ width: '90%', marginTop: 8 }}>
              <Col span={24}>
                <FormControl style={{ width: '100%', marginBottom: 10 }}>
                  <InputLabel id="select-label" key="activo">
                    <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                    <label>
                      {solicitudTipoArrendamientoMedico === 2
                        ? 'Tipo de vehículo'
                        : 'Activos para'}
                    </label>
                  </InputLabel>
                  <MuiSelect
                    labelId="select-label"
                    id="select"
                    key="activo1"
                    disabled={DisableForm}
                    value={formDestinoCredito}
                    label={
                      <>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        <label>
                          {solicitudTipoArrendamientoMedico == 2
                            ? 'Tipo de vehículo'
                            : 'Activos para'}
                        </label>
                      </>
                    }
                    onChange={(e) => setformDestinoCredito(e.target.value)}
                    size="small"
                  >
                    {optionsDestino.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
              </Col>
            </Row>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Row style={{ width: '90%', marginTop: 8 }}>
              <Col span={24}>
                <TextField
                  label={
                    <>
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      <label>Pago mensual</label>
                    </>
                  }
                  value={formMontoPago}
                  on
                  onChange={(e) => handleChangeMontoPago(e.target.value)}
                  onKeyDown={handleKeyDownMontoPago}
                  name="numberformat"
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                  variant="outlined"
                  key="numberMonto"
                  size="small"
                  style={{ width: '100%' }}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      color: 'black', // Color del texto dentro del campo
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                      color: 'black', // Color del label
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black', // Borde opcional (si usas Outlined)
                    },
                    '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled':
                      {
                        '-webkit-text-fill-color': 'rgb(0 0 0)', // Borde opcional (si usas Outlined)
                      },
                  }}
                />

                {/* <InputMask
                  mask="$ 9,999,999"
                  maskChar={null}
                  alwaysShowMask={false}
                  value={formMontoPago}
                  onChange={(e) => handleChangeMontoPago(e.target.value)}
                  disabled
                >
                  {(inputProps) => (
                    <TextField
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>¿Monto del pago?</label>
                        </>
                      }
                      {...inputProps}
                      size="small"
                      variant="outlined"
                      InputProps={{
                        style: { opacity: 0.5, pointerEvents: 'none' },
                      }}
                    />
                  )}
                </InputMask> */}
              </Col>
            </Row>
          </div>
          <div style={{ width: '100%', marginTop: 35, marginBottom: 50 }}>
            <Button
              className="button-forward"
              style={{ marginBottom: 10 }}
              type="primary"
              onClick={() => onFinish()}
              //htmlType="submit"
              disabled={false}
            >
              Continuar <ArrowRightOutlined />
            </Button>
          </div>
        </Skeleton>

        {/* <center>
          <Skeleton loading={loading} active={true}>
            <Form
              className="form-steps"
              form={form}
              style={{
                marginTop: -5,
                display: 'inline-block',
              }}
              name="horizontal_login"
              layout="inline"
              onFinish={onFinish}
              disabled={DisableForm}
            >
              <div
                style={{
                  overflow: 'auto',
                  maxHeight: windowSize.heightScroll,
                }}
              >
                <div>
                  <Row>
                    <Col span={24} style={{ textAlign: 'left' }}>
                      <Form.Item
                        name={['configuraCredito', 'montoInteres']}
                        style={{ paddingLeft: 0 }}
                        rules={[
                          {
                            required: true,
                            message: 'Ingresa el Monto de interés',
                          },
                        ]}
                        label={''}
                        colon={false}
                      >
                        <label>¿Monto a Financiar?</label>
                        <center>
                          <InputNumber
                            style={{ width: '103%' }}
                            prefix="$"
                            value={formMontoInteres}
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={handleChangeMontoInteres}
                          />
                        </center>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <Row>
                  <Col span={24} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['configuraCredito', 'periodoCredito']}
                      style={{ paddingLeft: 5 }}
                      label={''}
                      colon={false}
                    >
                      <label>Período de pago:</label>
                      <center>
                        <Slider
                          value={formPeriodos}
                          min={PeriodoMinimo}
                          max={PeriodoMaximo}
                          marks={Periodos}
                          step={12}
                          onChange={handleChangePeriodos}
                          // defaultValue={12}
                          disabled={DisableForm}
                        />
                        <div style={{ fontWeight: 'bold' }}>
                          {formPeriodos} Meses
                        </div>
                      </center>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24} style={{ textAlign: 'left' }}>
                    <label>Activo:</label>
                    <center>
                      <Select
                        value={formDestinoCredito}
                        style={{ marginLeft: 0, width: '100%' }}
                        placeholder="Seleccione una opción"
                        options={optionsDestino}
                        onChange={handleChangeDestinoCredito}
                      />
                    </center>
                  </Col>
                </Row>

                <Row>
                  <Col span={24} style={{ textAlign: 'left' }}>
                    <label>Monto del pago:</label>
                    <center>
                      <Input
                        value={formMontoPago}
                        onChange={handleChangeMontoPago}
                        disabled
                      />
                    </center>
                  </Col>
                </Row>
              </div>
              
            </Form>
          </Skeleton>
        </center> */}
      </div>
    </div>
  );
};

export default ConfigurarCredito;

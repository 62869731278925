import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './Welcome.css';
import { Carousel, Typography, Button, Collapse, Input } from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  LoginOutlined,
  MailOutlined,
  SaveOutlined,
} from '@ant-design/icons';



import { ComponentesContext } from '../contextos/componentesContext';

import AuthService from '../services/auth.service';
import UsuariosService from '../services/usuarios.Service';

const CambioContrasenaSinLogin = () => {
  const location = useLocation();

  const {
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    UpdateFlagtimeLineForm,
    UpdateFlagtimeLineFormProductos,
    ShowLoading, 
    UpdateFooterText,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;

  const [Correo, SetCorreo] = useState(
    typeof location.state.record.correo != 'undefined'
      ? location.state.record.correo != null
        ? location.state.record.correo
        : ''
      : ''
  );

  const [Otp, SetOtp] = useState(
    typeof location.state.record.otp != 'undefined'
      ? location.state.record.otp != null
        ? location.state.record.otp
        : ''
      : ''
  );

  const [Contrasena1, setContrasena1] = useState('');
  const [Contrasena2, setContrasena2] = useState('');

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 200,
    heightImage: window.innerHeight - 185,
  });

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - 200,
        heightImage: window.innerHeight - 185,
      });
    };

    UpdatebuttonIngresar('create');
    UpdateuserAvatar('');
    UpdateFlagtimeLineForm(false);
    UpdateFlagtimeLineFormProductos(false);
    UpdateFlagtimeLineFormAval(false);
      UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");

    window.addEventListener('resize', handleResize);
    //window.addEventListener("scroll", handleScroll);
    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNext = async (option) => {
    var regexp_password =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.])[A-Za-z\d$@$!%*?&#.]{8,15}/;

    if (Contrasena1 == '') {
      ModalMessage('Mensaje', 'Las contraseñas no pueden estar en blanco.');
      return false;
    }

    if (Contrasena1 != Contrasena2) {
      ModalMessage('Mensaje', 'Las contraseñas deben ser iguales.');
      return false;
    }

    if (!regexp_password.test(Contrasena1)) {
      ModalMessage(
        'Mensaje',
        <>
          La contraseña debe tener las siguientes características:
          <div>• Mínimo 8 caracteres y Máximo 15</div>
          <div>• Al menos una letra mayúscula</div>
          <div>• Al menos una letra minúscula</div>
          <div>• Al menos un dígito</div>
          <div>• No espacios en blanco</div>
          <div>• Al menos 1 carácter especial</div>
        </>
      );
      return false;
    }
      
    ShowLoading(true);

    const json = {
      email: Correo,
      otp: Otp,
      newPassword: Contrasena1,
    };

    let respuesta = await UsuariosService.postRestablecerPassword(json);

    ShowLoading(false);

    if (respuesta.result) {
      ModalMessage(
        'Mensaje',
        'La contraseña se modificó correctamente, ya puedes iniciar sesión con tus nuevas credenciales.'
      );

      navigate('/login', {
        state: { record: { correo: Correo } },
      });

      // handleNext("/dashboard");
    } else {
      ModalMessage('Mensaje', respuesta.msg);
      return false;
    }
  };

  return (
    <div style={{ backgroundColor: '#fff', marginTop: 5 }}>
      <center>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <table
          cellPadding={0}
          cellSpacing={0}
          style={{
            width: '40%',
            borderRadius: 15,
            marginLeft: 0,
          }}
          border={0}
        >
          <tbody>
            <tr>
              <td
                colSpan={3}
                style={{
                  textAlign: 'center',
                  borderTop: 'solid #7272cb 1px',
                  borderRadius: '15px 15px 0 0',
                  borderLeft: 'solid #7272cb 1px',
                  borderRight: 'solid #7272cb 1px',
                }}
              >
                <p>
                  <Title level={2} style={{ marginTop: -5, color: '#4c4cbc' }}>
                    Ingresar nueva contraseña
                  </Title>
                </p>
              </td>
            </tr>

            <tr>
              <td
                colSpan={3}
                style={{
                  textAlign: 'center',
                  height: 50,
                  borderLeft: 'solid #7272cb 1px',
                  borderRight: 'solid #7272cb 1px',
                }}
              >
                <Input.Password
                  placeholder="Ingrese contraseña..."
                  style={{ width: '80%', marginBottom: 20 }}
                  value={Contrasena1}
                  onChange={(e) => {
                    setContrasena1(e.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td
                colSpan={3}
                style={{
                  textAlign: 'center',
                  height: 50,
                  borderLeft: 'solid #7272cb 1px',
                  borderRight: 'solid #7272cb 1px',
                }}
              >
                <Input.Password
                  placeholder="Reingresar contraseña..."
                  style={{ width: '80%', marginBottom: 20 }}
                  value={Contrasena2}
                  onChange={(e) => {
                    setContrasena2(e.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td
                colSpan={3}
                style={{
                  textAlign: 'center',
                  height: 80,
                  borderBottom: 'solid #7272cb 1px',
                  borderLeft: 'solid #7272cb 1px',
                  borderRight: 'solid #7272cb 1px',
                  borderRadius: '0px 0px 15px 15px',
                }}
              >
                <Button
                  type="primary"
                  size={'default'}
                  style={{
                    backgroundColor: '#4c4cbc',
                    height: 40,
                    fontWeight: 'bold',
                    boxShadow: '0px -1px 10px 0px rgba(0,0,0,0.20)',
                  }}
                  onClick={() => handleNext('/login')}
                  className="account-welcome-btn-create"
                >
                  <SaveOutlined style={{ fontSize: '18px' }} /> {' Guardar '}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </center>
    </div>
  );
};

export default CambioContrasenaSinLogin;
